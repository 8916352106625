import {Alert, Modal} from "react-bootstrap";
import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import {analyzePDF} from "../APIcalls";

export function errorUpload({errorMessage, setShowError, setFile}) {
    function closeModal() {
        setShowError(false);
        errorMessage = null;
        setFile(null);
    }
    return (
        <Modal
            show={true}
            aria-labelledby="contained-modal-title"
            onHide={closeModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title">
                    An Error during uploading the file.
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errorMessage}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closeModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export function errorDrawing({errorMessage}) {
    console.log(errorMessage);
}

export function WarningNotComplete({showWarning, titleMessage, bodyMessage, setTask, currentTask, taskSelected, setShowWarning}) {
    return (
        <Modal
            show={showWarning}
            aria-labelledby="contained-modal-title"
            onHide={() => {
                setTask(currentTask);
                setShowWarning(false);
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title">
                    {titleMessage}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {bodyMessage}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    setTask(currentTask);
                    setShowWarning(false);
                }}>Stay on step {currentTask}</Button>
                <Button variant="light" onClick={() => setTask(taskSelected)}>Go to step {taskSelected}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export function WarningDownload({showWarning, titleMessage, bodyMessage, handleDownload, setShowWarning}) {
    return (
        <Modal
            show={showWarning}
            aria-labelledby="contained-modal-title"
            onHide={() => {
                setShowWarning(false);
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title">
                    {titleMessage}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {bodyMessage}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    setShowWarning(false);
                }}>Update Meta Information</Button>
                <Button variant="light" onClick={() => {
                    handleDownload();
                    setShowWarning(false);
                }}>Download</Button>
            </Modal.Footer>
        </Modal>
    );
}

export function SurveyInfo({showWarning, pdfInfo, setTask, setShowWarning}) {
    const [allowAnalyzingPDF, setAllowAnalyzingPDF] = useState(false);
    return (
        <Modal
            show={showWarning}
            aria-labelledby="contained-modal-title"
            onHide={() => {
                setShowWarning(false);
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title">
                    PAVE 2.0 Survey: Tell us what you think!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                PAVE 2.0 is part of the SNSF research project (Accessible Scientific PDFs for All) to develop a novel
                method to remediate PDFs. We would really appreciate your input to improve the tool and learn more about
                your PDF remediation experience.<br/><br/>
                This survey will take about 15 minutes, so grab a beverage and help us to improve PAVE 2.0.
                The results will be published anonymized in a scientific publication.
                If you have more in-depth comments or suggestions, please contact Felix Schmitt (scmx@zhaw.ch) with any information  you'd like to share.<br/><br/>
                Beside filling out the survey, you can help us by allowing us to analyze the accessibility of your PDF.<br/><br/>
                <div className="d-flex flex-row justify-content-evenly">
                    <Button onClick={() => {
                        analyzePDF({pdfInfo: pdfInfo});
                        setAllowAnalyzingPDF(true);
                    }}
                            disabled={allowAnalyzingPDF}> {allowAnalyzingPDF ? "Thank you for your help" : "Allow to analyze your PDF"}</Button>
                    <Button
                        href={"https://docs.google.com/forms/d/e/1FAIpQLSfPJro6fWypn8BlEhcq6OjIPm0kH0XMhHCueJfM-gnKlQxACg/viewform?usp=sf_link"}
                        target="_blank"> Survey Link </Button>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    setTask(0);
                    setShowWarning(false);
                }}>New PDF</Button>
                <Button variant="light" onClick={() => {
                    setShowWarning(false);
                }}>Return</Button>
            </Modal.Footer>
        </Modal>
    );
}

export function SuccessDownload({showWarning, titleMessage, bodyMessage, setTask, setShowWarning}) {
    return (
        <Modal
            show={showWarning}
            aria-labelledby="contained-modal-title"
            onHide={() => {
                setShowWarning(false);
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title">
                    {titleMessage}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {bodyMessage}
                <br/><strong>Please check the remediated PDF carefully, as parsing errors such as missing letters can occur.</strong><br/>
                <strong>Please help us by filling out this <a href="https://forms.gle/w8mnYCySLbeRc5P36"
                                                              target="_blank">Google
                    Formular</a></strong>
            </Modal.Body>
            <Modal.Footer>
        <Button onClick={() => {
            setTask(0);
                    setShowWarning(false);
                }}>New PDF</Button>
                <Button variant="light" onClick={() => {
                    setShowWarning(false);
                }}>Return</Button>
            </Modal.Footer>
        </Modal>
    );
}
