import React from "react";
import { Link } from "react-router-dom";

const Step6 = () => {
    return <div className="instructionsText">
        <Link to="/help#whyDoIHaveToWriteAnAlternativeTextForFigures" target={"_blank"}>More information about alternative text of figures</Link>.
        <ul>
            <li><strong>Figure is decorative:</strong> Select <em>is decorative</em> so it is ignored by the screen reader</li>
        </ul>
    </div>
}

export default Step6;
