import React from "react";
import {Link} from "react-router-dom";

const Step7MathEditor = () => {
    return <div className="instructionsText">
        <Link to="/help#whatIsAMathEditor" target={"_blank"}>More information about the math editor</Link>.
        <ul>
            <li><strong>Top left window:</strong> The top left window shows the original mathematical formula.</li>
            <li><strong>Top right window:</strong> The top right window shows the current LaTeX code of the mathematical
            formula. You can edit it. It is synchronized with the bottom window.</li>
            <li><strong>Bottom window:</strong> The bottom window shows an interactive rendering of the current
                mathematical formula. You can edit it with the mathematical keyboard or by writing LaTeX commands.</li>
            <li><strong>Formula correct:</strong> Use the <em>Generate Alternative Text</em> to automatically generate
                the alternative text, which follows the MathSpeak rules.</li>
        </ul>
    </div>
}

export default Step7MathEditor;
