import React from "react";

export function startResizeX(e) {
    return document.getElementById("menu").clientWidth - e.clientX;
}

export function resizingX(e, startResizeValue, setMenuSize) {
    if (startResizeValue != null) {
        setMenuSize(() => startResizeValue + e.clientX);
    }
}

export function startResizeY(e) {
    return document.getElementById("instructions").clientHeight - e.clientY;
}

export function resizingY(e, startResizeValue, setInstructionSize) {
    if (startResizeValue != null) {
        setInstructionSize(() => startResizeValue + e.clientY);
    }
}

