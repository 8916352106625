import * as pdfjsLib from 'pdfjs-dist/webpack.mjs';

const WHITESPACETHRESHOLD = 150

const getBoundingBoxForTextItem = (item, pageViewport, pageNum) => {
    // Apply page viewport transform and item transform
    const itemRotated = pdfjsLib.Util.transform(pageViewport.transform, item.transform);
    const x = itemRotated[4];
    const y = itemRotated[5];

    // item.height is not correct for some cases (https://github.com/mozilla/pdf.js/issues/8276)
    const height = Math.sqrt((itemRotated[2] * itemRotated[2]) + (itemRotated[3] * itemRotated[3]));
    const width =  Math.sqrt((itemRotated[0] * itemRotated[0]) + (itemRotated[1] * itemRotated[1])) * item.width;
    const itemAngle =  Math.atan2(itemRotated[2], itemRotated[0]);

    // This will provide top left, clockwise bounding box
    const horizontalBoundingBox = [
        x, pageViewport.height - y,
        x + width, pageViewport.height - y,
        x + width, pageViewport.height - y + height,
        x, pageViewport.height - y + height
    ];

    // return rotated bounding box in pixels
    const rotatedBoundingBox = rotateBoundingBox(horizontalBoundingBox, [x, y], itemAngle);

    return {
        page: pageNum,
        llx: Math.max(rotatedBoundingBox[0], 0),
        lly: Math.max(pageViewport.height - rotatedBoundingBox[5], 0),
        urx: Math.min(rotatedBoundingBox[4], pageViewport.width),
        ury: Math.min(pageViewport.height -rotatedBoundingBox[1], pageViewport.height)
    };
}

const rotateBoundingBox = (absBox, origin, rads) => {
    const [originX, originY] = origin;
    const cosT = Math.cos(rads);
    const sinT = Math.sin(rads);

    const rotate = (x, y) => ([
        (x - originX) * cosT - (y - originY) * sinT + originX,
        (x - originX) * sinT + (y - originY) * cosT + originY
    ]);

    const [v1x, v1y, v2x, v2y, v3x, v3y, v4x, v4y] = absBox;
    return [
        ...rotate(v1x, v1y),
        ...rotate(v2x, v2y),
        ...rotate(v3x, v3y),
        ...rotate(v4x, v4y)
    ];
};

export function processPdfJSRenderingInformation({pdf, pageNum}) {
    const pdfJSOperatorKeys = Object.keys(pdfjsLib.OPS);
    let pdfJSOperators = [];
    Object.values(pdfjsLib.OPS).forEach((v, i) => {
        pdfJSOperators[v] = pdfJSOperatorKeys[i];
    });

    return pdf.getPage(pageNum).then((page) => {
        const viewport = page.getViewport({scale: 1});
        let lastTextFont = null;
        let lastTextMatrix = [1, 0, 0, 1, 0, 0];
        let lastTransform = [1, 0, 0, 1, 0, 0];
        let textX = 0;
        let charSpacing = 0;
        let lineWidth = 1.0;
        let wordSpacing = 0;
        let addSpace = false;
        let textLeading = 0;
        const pageSize = {page: pageNum,llx: viewport.viewBox[0], lly: viewport.viewBox[1], urx: viewport.viewBox[2], ury: viewport.viewBox[3]};
        let stateHistory = [];
        let clipBBox = viewport.viewBox;
        [clipBBox[0], clipBBox[1]] = pdfjsLib.Util.applyTransform([clipBBox[0], clipBBox[1]], viewport.transform);
        [clipBBox[2], clipBBox[3]] = pdfjsLib.Util.applyTransform([clipBBox[2], clipBBox[3]], viewport.transform);
        clipBBox = [Math.min(clipBBox[0], clipBBox[2]), Math.min(clipBBox[1], clipBBox[3]), Math.max(clipBBox[0], clipBBox[2]), Math.max(clipBBox[1], clipBBox[3])];
        return page.getOperatorList().then(opList => {
            let textOperators = [];
            let imageOperators = [];
            let shapeOperators = [];
            let lastX = 0;
            let lastY = 0;
            for (let opI = 0; opI < opList.argsArray.length; opI++) {
                const op = opList.argsArray[opI];

                // 44
                if (opList.fnArray[opI] === pdfjsLib.OPS.showText) {

                    let text = op[0].map(char => {
                        if (char.unicode != null) return char.unicode;
                        else if (typeof char === 'number' && char < -WHITESPACETHRESHOLD) return " ";
                    });
                    if (addSpace) {
                        text.unshift(" ");
                        addSpace = false;
                    }
                    const item = {
                        transform: [
                            lastTextFont[1] * lastTextMatrix[0],
                            lastTextFont[1] * lastTextMatrix[1],
                            lastTextFont[1] * lastTextMatrix[2],
                            lastTextFont[1] * lastTextMatrix[3],
                            lastTextMatrix[4],
                            lastTextMatrix[5]],
                        width: op[0].reduce((accumulator, currentValue) => {
                            if (currentValue.width != null) {
                                return accumulator + (currentValue.width / 1000) + charSpacing;
                            }
                            else if (typeof currentValue === 'number') {
                                return accumulator - currentValue / 1000;
                            }
                            else {
                                return accumulator;
                            }
                        }, 0)
                    }

                    // count words
                    let whitespaceCount = 0;
                    let isPreviousWhitespace = false;
                    const arrayOfChars = op[0].map(e => e.unicode);

                    for (let i = 0; i < arrayOfChars.length; i++) {
                        if (arrayOfChars[i] === " ") {
                            if (!isPreviousWhitespace) {
                                whitespaceCount++;
                                isPreviousWhitespace = true;
                            }
                        } else {
                            isPreviousWhitespace = false;
                        }
                    }
                    item.width += whitespaceCount * wordSpacing;

                    // normal mode
                    if (viewport.rotation === 0 || viewport.rotation === 180) {
                        item.transform[4] += textX;
                    }
                    // landscape mode
                    if (viewport.rotation === 90 || viewport.rotation === 270) {
                        item.transform[5] += textX;
                    }

                    const textBbox = getBoundingBoxForTextItem(item, viewport, pageNum);
                    textOperators.push({
                        type:"text",
                        text: text.join(""),
                        op: "", //op
                        textFont: lastTextFont[1] != null ? [lastTextFont[0], lastTextFont[1] * lastTextMatrix[3]].toString() : [lastTextFont[0], lastTextMatrix[3]].toString(),
                        textMatrix: lastTextMatrix.toString(),
                        lastTransform: lastTransform.toString(),
                        textX: textX,
                        boundingBox: textBbox,
                        id: opI,
                        unicodes: op[0].map(char => char.unicode),
                        widths: op[0].map(char => char.width + charSpacing),
                        originalCharCodes: op[0].map(char => char.originalCharCode),

                    });
                    textX += (textBbox.urx - textBbox.llx);
                }

                // 31
                else if (opList.fnArray[opI] === pdfjsLib.OPS.beginText) {
                    lastTextMatrix = JSON.parse(JSON.stringify(lastTransform));
                }

                // 36
                else if (opList.fnArray[opI] === pdfjsLib.OPS.setLeading) {
                    textLeading = op[0];
                }

                // 37
                else if (opList.fnArray[opI] === pdfjsLib.OPS.setFont) {
                    lastTextFont = op;
                }

                // 40
                else if (opList.fnArray[opI] === pdfjsLib.OPS.moveText) {
                    textX = 0;
                    addSpace = true;
                    [lastTextMatrix[4], lastTextMatrix[5]] = pdfjsLib.Util.applyTransform(op, lastTextMatrix);
                }

                // 41
                else if (opList.fnArray[opI] === pdfjsLib.OPS.setLeadingMoveText) {
                    textX = 0;
                    addSpace = true;
                    [lastTextMatrix[4], lastTextMatrix[5]] = pdfjsLib.Util.applyTransform(op, lastTextMatrix);
                    textLeading = -op[1] * lastTextMatrix[0];
                }

                // 42
                else if (opList.fnArray[opI] === pdfjsLib.OPS.setTextMatrix) {
                    lastTextMatrix = pdfjsLib.Util.transform(lastTransform, op);
                    textX = 0;
                    if (textOperators.length === 0 ||
                        Math.abs(lastTextMatrix[4] - textOperators[textOperators.length - 1].boundingBox.urx) > WHITESPACETHRESHOLD ||
                        Math.abs(lastTextMatrix[5] - textOperators[textOperators.length - 1].boundingBox.lly) > 0.5 * lastTextMatrix[4] ) {
                        addSpace = true;
                    }
                }

                // 43
                else if (opList.fnArray[opI] === pdfjsLib.OPS.nextLine) {
                    textX = 0;
                    addSpace = true;
                    lastTextMatrix[5] -= textLeading;
                }

                // 12
                else if (opList.fnArray[opI] === pdfjsLib.OPS.transform) {
                    lastTransform = pdfjsLib.Util.transform(lastTransform, op);
                }

                // 85
                else if (opList.fnArray[opI] === pdfjsLib.OPS.paintImageXObject) {
                    const item = {
                        transform: lastTransform,
                        width: 1
                    }
                    const boundingBox = getBoundingBoxForTextItem(item, viewport, pageNum);
                    imageOperators.push({
                        type:"ImageXObject",
                        op: "", //op
                        transform: lastTransform.toString(),
                        boundingBox: boundingBox,
                        id: opI
                    });
                }

                // 91
                else if (opList.fnArray[opI] === pdfjsLib.OPS.constructPath) {
                    let llx = 99999999;
                    let lly = 99999999;
                    let urx =  -99999999;
                    let ury = -99999999;
                    let startX = 0;
                    let startY = 0;
                    let shapeStart = 0;
                    let lastXShape = 0;
                    let lastYShape = 0;
                    const transform = pdfjsLib.Util.transform(viewport.transform, lastTransform);
                    let shapeText = "ref x: " + transform[4] + ", " + "ref y: " + transform[5];
                    for (let drawingOperator = 0; drawingOperator < op[0].length; drawingOperator++) {
                        // bounding box of the shape instruction
                        let boundingBox = null;

                        // 13 , 2 arguments
                        if (op[0][drawingOperator] === pdfjsLib.OPS.moveTo) {
                            shapeText += ", MoveTo: " + op[1][shapeStart] + ", " + op[1][shapeStart + 1];
                            lastXShape = op[1][shapeStart];
                            lastYShape = op[1][shapeStart + 1];
                            startX = JSON.parse(JSON.stringify(lastXShape));
                            startY = JSON.parse(JSON.stringify(lastYShape));
                            shapeStart += 2;
                        }

                        // 14 , 2 arguments
                        else if (op[0][drawingOperator] === pdfjsLib.OPS.lineTo) {
                            shapeText += ", LineTo: " + op[1][shapeStart] + ", " + op[1][shapeStart + 1];
                            const x = Math.min(lastXShape, op[1][shapeStart]);
                            const y = Math.min(lastYShape, op[1][shapeStart + 1]);
                            const width = Math.max(Math.abs(lastXShape - op[1][shapeStart]), 1);
                            const height = Math.max(Math.abs(lastYShape - op[1][shapeStart + 1]), 1);
                            boundingBox = {page: pageNum, llx: x, lly: y, urx: x + width, ury: y + height};
                            lastXShape = op[1][shapeStart];
                            lastYShape = op[1][shapeStart + 1];
                            shapeStart += 2;
                        }

                        // 15 bezier curve, 6 arguments
                        else if (op[0][drawingOperator] === pdfjsLib.OPS.curveTo) {
                            shapeText += ", CurveTo: " + op[1][shapeStart] + ", " + op[1][shapeStart + 1] + ", " + op[1][shapeStart + 2] + ", " + op[1][shapeStart + 3] + ", " + op[1][shapeStart + 4] + ", " + op[1][shapeStart + 5];
                            const bezierBbox = pdfjsLib.Util.bezierBoundingBox(lastXShape, lastYShape, op[1][shapeStart], op[1][shapeStart + 1], op[1][shapeStart + 2], op[1][shapeStart + 3], op[1][shapeStart + 4], op[1][shapeStart + 5]);
                            boundingBox = {llx: bezierBbox[0], lly: bezierBbox[1], urx: bezierBbox[2], ury: bezierBbox[3]};
                            lastXShape = op[1][shapeStart + 4];
                            lastYShape = op[1][shapeStart + 5];
                            shapeStart += 6;
                        }

                        // 16 bezier curve2, 4 arguments
                        else if (op[0][drawingOperator] === pdfjsLib.OPS.curveTo2) {
                            shapeText += ", CurveTo2: " + op[1][shapeStart] + ", " + op[1][shapeStart + 1] + ", " + op[1][shapeStart + 2] + ", " + op[1][shapeStart + 3];
                            const bezierBbox = pdfjsLib.Util.bezierBoundingBox(lastXShape, lastYShape, lastXShape, lastYShape, op[1][shapeStart], op[1][shapeStart + 1], op[1][shapeStart + 2], op[1][shapeStart + 3]);
                            boundingBox = {llx: bezierBbox[0], lly: bezierBbox[1], urx: bezierBbox[2], ury: bezierBbox[3]};
                            lastXShape = op[1][shapeStart + 2];
                            lastYShape = op[1][shapeStart + 3];
                            shapeStart += 4;
                        }

                        // 17 bezier curve3, 4 arguments
                        else if (op[0][drawingOperator] === pdfjsLib.OPS.curveTo3) {
                            shapeText += ", CurveTo3: " + op[1][shapeStart] + ", " + op[1][shapeStart + 1] + ", " + op[1][shapeStart + 2] + ", " + op[1][shapeStart + 3];
                            const bezierBbox = pdfjsLib.Util.bezierBoundingBox(lastXShape, lastYShape, op[1][shapeStart], op[1][shapeStart + 1], op[1][shapeStart + 2], op[1][shapeStart + 3], op[1][shapeStart + 2], op[1][shapeStart + 3]);
                            boundingBox = {llx: bezierBbox[0], lly: bezierBbox[1], urx: bezierBbox[2], ury: bezierBbox[3]};
                            lastXShape = op[1][shapeStart + 2];
                            lastYShape = op[1][shapeStart + 3];
                            shapeStart += 4;
                        }

                        // 18
                        else if (op[0][drawingOperator] === pdfjsLib.OPS.closePath) {
                            shapeText += ", ClosePath: " + startX + ", " + startY;
                            lastXShape = JSON.parse(JSON.stringify(startX));
                            lastYShape = JSON.parse(JSON.stringify(startY));
                        }

                        // 19 , 4 arguments
                        else if (op[0][drawingOperator] === pdfjsLib.OPS.rectangle) {
                            // if height is negative
                            if (op[1][shapeStart + 3] < 0) {
                                op[1][shapeStart + 1] += op[1][shapeStart + 3];
                                op[1][shapeStart + 3] *= -1;
                            }
                            // if width is negative
                            if (op[1][shapeStart + 2] < 0) {
                                op[1][shapeStart] += op[1][shapeStart + 2];
                                op[1][shapeStart + 2] *= -1;
                            }
                            boundingBox = {
                                page: pageNum,
                                llx: op[1][shapeStart],
                                lly: op[1][shapeStart + 1],
                                urx: op[1][shapeStart] + op[1][shapeStart + 2],
                                ury: op[1][shapeStart + 1] + op[1][shapeStart + 3]
                            };
                            shapeText += ", Rect: " + op[1][shapeStart] + ", " + op[1][shapeStart + 1] + ", " + op[1][shapeStart + 2] + ", " + op[1][shapeStart + 3];
                            lastXShape = boundingBox.llx;
                            lastYShape = boundingBox.lly;
                            shapeStart += 4;
                        }
                        else {
                            console.log(op[0][drawingOperator])
                        }
                        // if a bounding box was set
                        if (boundingBox != null) {
                            // transform bounding box dimensions
                            [boundingBox.llx, boundingBox.lly] = pdfjsLib.Util.applyTransform([boundingBox.llx - lineWidth, boundingBox.lly - lineWidth], transform);
                            [boundingBox.urx, boundingBox.ury] = pdfjsLib.Util.applyTransform([boundingBox.urx + lineWidth, boundingBox.ury + lineWidth], transform);
                            llx = Math.min(boundingBox.llx, boundingBox.urx, llx);
                            lly = Math.min(boundingBox.lly, boundingBox.ury, lly);
                            urx = Math.max(boundingBox.llx, boundingBox.urx, urx);
                            ury = Math.max(boundingBox.lly, boundingBox.ury, ury);
                        }
                    }
                    // add the shape operator bounding box
                    if (isNaN(urx) || urx === -99999999) {
                        //console.log("something went wrong")
                    }
                    else {
                        const bboxNotClipped = {
                            page: pageNum,
                            llx: llx,
                            lly: lly,
                            urx: urx,
                            ury: ury
                        };
                        shapeOperators.push({
                            type:"Shape",
                            op: shapeText, //op
                            boundingBox: {
                                page: pageNum,
                                llx: Math.max(bboxNotClipped.llx, clipBBox[0]),
                                lly: Math.max(bboxNotClipped.lly, clipBBox[1]),
                                urx: Math.min(bboxNotClipped.urx, clipBBox[2]),
                                ury: Math.min(bboxNotClipped.ury, clipBBox[3])
                            },
                            boundingBoxNotClipped: bboxNotClipped,
                            clipBBox: clipBBox,
                            transform: transform,
                            lastX: lastX,
                            lastY: lastY,
                            id: opI,
                        });
                        lastX = bboxNotClipped.llx;
                        lastY = bboxNotClipped.lly;
                    }
                }

                // 29
                else if (opList.fnArray[opI] === pdfjsLib.OPS.clip) {
                    const shapeOperator = shapeOperators.pop();
                    if (shapeOperator != null) {
                        const shapeRect = shapeOperator.boundingBoxNotClipped;
                        // check if shape rect is inside the clip box
                        if (shapeRect.llx >= clipBBox[0] && shapeRect.lly >= clipBBox[1] && shapeRect.urx <= clipBBox[2] && shapeRect.ury <= clipBBox[3]) {
                            clipBBox = [
                                shapeOperator.boundingBoxNotClipped.llx,
                                shapeOperator.boundingBoxNotClipped.lly,
                                shapeOperator.boundingBoxNotClipped.urx,
                                shapeOperator.boundingBoxNotClipped.ury
                            ];
                        }
                        else {
                            clipBBox = [
                                Math.max(clipBBox[0], shapeOperator.boundingBoxNotClipped.llx),
                                Math.max(clipBBox[1], shapeOperator.boundingBoxNotClipped.lly),
                                Math.min(clipBBox[2], shapeOperator.boundingBoxNotClipped.urx),
                                Math.min(clipBBox[3], shapeOperator.boundingBoxNotClipped.ury)
                            ];
                        }
                    }
                }

                // 30
                else if (opList.fnArray[opI] === pdfjsLib.OPS.eoClip) {
                    const shapeOperator = shapeOperators.pop();
                    if (shapeOperator != null) {
                        // even odd winding rule -> intersection
                        clipBBox = [
                            Math.max(clipBBox[0], shapeOperator.boundingBoxNotClipped.llx),
                            Math.max(clipBBox[1], shapeOperator.boundingBoxNotClipped.lly),
                            Math.min(clipBBox[2], shapeOperator.boundingBoxNotClipped.urx),
                            Math.min(clipBBox[3], shapeOperator.boundingBoxNotClipped.ury)
                        ]
                    }
                }

                // 10
                else if (opList.fnArray[opI] === pdfjsLib.OPS.save) {
                    stateHistory.push({
                        lastTextFont: JSON.parse(JSON.stringify(lastTextFont)),
                        lastTextMatrix: JSON.parse(JSON.stringify(lastTextMatrix)),
                        lastTransform: JSON.parse(JSON.stringify(lastTransform)),
                        clipBBox: JSON.parse(JSON.stringify(clipBBox))
                    });
                }

                // 11
                else if (opList.fnArray[opI] === pdfjsLib.OPS.restore) {
                    const lastState = stateHistory.pop();
                    if (lastState != null) {
                        lastTextFont = lastState.lastTextFont;
                        lastTextMatrix = lastState.lastTextMatrix;
                        lastTransform = lastState.lastTransform;
                        clipBBox = lastState.clipBBox;
                    }
                }

                // 74
                else if (opList.fnArray[opI] === pdfjsLib.OPS.paintFormXObjectBegin) {
                    stateHistory.push({
                        lastTextFont: JSON.parse(JSON.stringify(lastTextFont)),
                        lastTextMatrix: JSON.parse(JSON.stringify(lastTextMatrix)),
                        lastTransform: JSON.parse(JSON.stringify(lastTransform)),
                        clipBBox: JSON.parse(JSON.stringify(clipBBox))
                    });
                    const newTransform = pdfjsLib.Util.transform(lastTransform, op[0]);
                    lastTransform = newTransform;
                    lastTextMatrix = newTransform;
                }

                // 75
                else if (opList.fnArray[opI] === pdfjsLib.OPS.paintFormXObjectEnd) {
                    const lastState = stateHistory.pop();
                    if (lastState != null) {
                        lastTextFont = lastState.lastTextFont;
                        lastTextMatrix = lastState.lastTextMatrix;
                        lastTransform = lastState.lastTransform;
                    }
                }

                // 1
                else if (opList.fnArray[opI] === pdfjsLib.OPS.dependency) {

                }

                // 2
                else if (opList.fnArray[opI] === pdfjsLib.OPS.setLineWidth) {
                    lineWidth = op[0];
                }

                // 3
                else if (opList.fnArray[opI] === pdfjsLib.OPS.setLineCap) {

                }

                // 4
                else if (opList.fnArray[opI] === pdfjsLib.OPS.setLineJoin) {

                }

                // 22
                else if (opList.fnArray[opI] === pdfjsLib.OPS.fill) {
                    // does not influence the rendering
                }

                // 23
                else if (opList.fnArray[opI] === pdfjsLib.OPS.eoFill) {

                }

                // 28
                else if (opList.fnArray[opI] === pdfjsLib.OPS.endPath) {
                    //console.log("endPath");
                }

                // 32
                else if (opList.fnArray[opI] === pdfjsLib.OPS.endText) {

                }

                // 33
                else if (opList.fnArray[opI] === pdfjsLib.OPS.setCharSpacing) {
                    charSpacing = op[0]
                }

                // 34
                else if (opList.fnArray[opI] === pdfjsLib.OPS.setWordSpacing) {
                    wordSpacing = op[0];
                }

                // 38
                else if (opList.fnArray[opI] === pdfjsLib.OPS.setTextRenderingMode) {
                    // does not influence the rendering
                }

                // 58
                else if (opList.fnArray[opI] === pdfjsLib.OPS.setStrokeRGBColor) {
                    // does not influence the rendering
                }

                // 59
                else if (opList.fnArray[opI] === pdfjsLib.OPS.setFillRGBColor) {
                    // does not influence the rendering
                }

                // 63
                else if (opList.fnArray[opI] === pdfjsLib.OPS.beginInlineImage) {
                    console.log("Inline Image not Implemented")
                }

                // 64
                else if (opList.fnArray[opI] === pdfjsLib.OPS.beginImageData) {
                    console.log("Image Data not implemented")
                }

                // 69
                else if (opList.fnArray[opI] === pdfjsLib.OPS.beginMarkedContent) {
                    // does not influence the rendering
                }

                // 70
                else if (opList.fnArray[opI] === pdfjsLib.OPS.beginMarkedContentProps) {
                    // does not influence the rendering
                }

                // 70
                else if (opList.fnArray[opI] === pdfjsLib.OPS.endMarkedContent) {
                    // does not influence the rendering
                }

                // other operators
                else {
                    //console.log(opI + ": " + pdfJSOperators[opList.fnArray[opI]]);
                    //console.log(op);
                }

            }
            console.log("Processed Page: " + pageNum);
            return {textOperators: textOperators, imageOperators: imageOperators, shapeOperators: shapeOperators, pageSize: pageSize};
        });
    });
}
