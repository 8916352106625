import TutorialVideo from "./utils/TutorialVideo";
import React from "react";

export function helpContent() {
    return <>
        <h2>Help</h2>

        <h3 id="WhatDoesPaveStandFor">What does PAVE stand for?</h3>
        <p>
            PAVE stands
            for <strong>P</strong>DF <strong>A</strong>ccessibility <strong>V</strong>alidation <strong>E</strong>ngine.
            It is a tool designed to ensure that PDF documents are accessible to individuals with disabilities.
        </p>

        <h3 id="WhyShouldIMakeAPDFAccessible">Why should I make a PDF accessible?</h3>
        <p>
            People with visual impairments rely on assistive technologies, such as screen readers, to access
            content.
            For screen readers to properly interpret PDF documents, the PDFs must be properly tagged with the
            necessary metadata.
            Unfortunately, many PDFs are not tagged correctly, which can hinder accessibility.
        </p>

        <h3 id="WhatIsAScreenReader">What is a screen reader?</h3>
        <p>
            A screen reader is a software that helps people with visual impairments by reading the content of a
            screen aloud
            or converting it into braille. Screen readers allow users to navigate documents using keyboard
            shortcuts.
        </p>

        <h3 id="whatIsARegion">What is a region in a PDF?</h3>
        <p>
            In PDF documents, a "region" refers to a logical grouping of content with a specific meaning. For
            example,
            a table should be marked with a "table" region, which includes all the visual elements of the table.
            The "Artifact" region type is used for content that should not be read aloud by screen readers, such
            as decorative elements.
        </p>
        <TutorialVideo src="/videos/Step1.mov"/>

        <h3 id="whatIsAReadingOrder">What is a reading order?</h3>
        <p>
            The reading order determines how a document is read by a screen reader. It ensures that content is
            read in a logical
            and sequential order, such as placing figures and captions after their reference in the text but not
            in the middle of a paragraph.
        </p>
        <TutorialVideo src="/videos/Step2.mov"/>

        <h3 id="whyIsHeadingStructureCrucial">Why is the heading structure important?</h3>
        <p>
            Screen reader users often navigate documents by heading structure. For optimal navigation, headings
            should be
            properly marked, and their hierarchy must be correct (e.g., H1 should be followed by H2, not skipped
            to H3).
            Headings should start with level 1, and the Title tag should only appear once in the heading’s
            structure.
        </p>
        <TutorialVideo src="/videos/Step3.mov"/>

        <h3 id="whyDoIHaveToDefineTheTableStructure">Why is defining the table structure important?</h3>
        <p>
            Screen readers cannot automatically detect table structures, so you need to define the relationships
            between
            table cells, rows, and columns. Additionally, mark header cells to improve navigation. Avoid during
            creating your document merged cells or multiple
            header rows and columns, as these can confuse screen readers.
        </p>
        <TutorialVideo src="/videos/Step4.mov"/>

        <h3 id="whyDoIHaveToDefineTheListStructure">Why is defining the list structure important?</h3>
        <p>
            Like tables, screen readers cannot automatically detect list structures. You must ensure that each
            list item is properly
            marked, and nested lists should be indicated within other lists if needed. This ensures proper
            navigation and understanding.
        </p>
        <TutorialVideo src="/videos/Step5.mov"/>

        <h3 id="whyDoIHaveToWriteAnAlternativeTextForFigures">Why do I need alternative text for figures?</h3>
        <p>
            Figures often contain important information that needs to be accessible. To make figures accessible
            to screen readers,
            you should provide descriptive alternative text. If the figure is purely decorative (e.g., a logo),
            mark it as an artifact.
            The alternative text of figures should not be a copy of the
            caption. Instead it should describe the key information of the figure. To check the written
            alternative
            text,
            you can use image generators, such as ChatGPT. For scientific charts, you should have a short
            overview (1-2
            sentences) with information about the chart type, title, axis names, axis range, and legend. After
            this, you
            should describe prominent visual elements, such as extreme values, trends, or comparisons in up to 7
            sentences.
        </p>
        <TutorialVideo src="/videos/Step6.mov"/>

        <h3 id="whyDoIHaveToWriteAnAlternativeTextForFormulas">Why do I need alternative text for mathematical
            formulas?</h3>
        <p>
            Just like figures, mathematical formulas need alternative text to ensure that screen readers can
            interpret them.
            However, creating alternative text for mathematical formulas is challenging even for experts,
            because the alternative
            text has to follow clear rules to be not ambiguous. As a result, we propose to use the math editor.
            The user
            only has to reconstruct the mathematical formula by editing the LaTeX code or by using the
            interactive
            editor. The alternative text, which follows the MathSpeak rules is then generated automatically.
        </p>

        <h3 id="whatIsAMathEditor">What is a math editor?</h3>
        <p>
            A math editor is a tool that helps create alternative text for mathematical formulas. By using LaTeX
            code or an interactive
            editor, the formula is reconstructed, and the corresponding alternative text, following the
            MathSpeak rules, is generated automatically.
        </p>
        <TutorialVideo src="/videos/Step7.mov"/>

        <h3 id="whyDoIHaveToAddMetaInformation">Why do I need to add meta information to a PDF?</h3>
        <p>
            Meta information, such as the document’s title, author, and language, enhances accessibility. While
            most meta information is set automatically, it’s essential that the user defines these details to
            ensure proper screen reader functionality.
        </p>
        <TutorialVideo src="/videos/Step8.mov"/>
    </>
}

export function legalContent() {
    return <>
        <h2>Legal Information</h2>

        <h3>Collection and Use of Information</h3>
        <p>Upon accessing the website, various access data (e.g. IP address, date and time of access, name of accessed
            file, etc.) is stored.
            No personal analysis takes place. For statistical purposes, data is analyzed anonymously, such as to
            determine how many hits the portal had on a given day.
            This data is treated with strict confidentiality and is not shared or provided to third parties unless this
            is required by applicable law and specifically by the responsible criminal prosecution authority.</p>

        <h3>Cookies</h3>
        <p>When using the portal, the user's computer stores a small file ("cookies"). This file is used only to
            anonymously collect visitor statistics.</p>

        <h3>Data Security</h3>
        <p>Secure storage of personal data (e.g. regarding newsletter subscriptions) is guaranteed.</p>

        <h3>Image Copyrights</h3>
        <p>All photos and images used on this website are protected by copyright. All use or dissemination of the photos
            on this website require the express consent of the ZHAW - ICT Accessibility Lab. ZHAW - ICT Accessibility
            Lab reserves the right to take legal action in the event that the photos are used without consent.</p>

        <h3>Liability</h3>
        <p>This website is for information purposes only. ZHAW - ICT Accessibility Lab is not liable for the
            completeness and accuracy of the published information or for information from third parties that is
            accessible via the PAVE site.</p>
    </>
}

export function contactContent() {
    return <>
        <h2>Contact</h2>

        <h3>ZHAW</h3>
        <a href="http://accessibility.zhaw.ch/?L=0">ICT Accessibility Lab</a><br/>
        <a href="https://www.zhaw.ch/en/engineering/institutes-centres/init">Init - Institute of Applied Information
            Technology</a><br/>
        <a href="https://www.zhaw.ch/en/university">ZHAW - Zurich University of Applied Sciences</a><br/>
        <br/>

        <h3>Contact Persons</h3>
        <a href="https://www.zhaw.ch/en/about-us/person/scmx/">Felix Schmitt-Koopmann</a><br/>
        Steinberggasse 13<br/>
        8400 Winterthur<br/>
        <a href="mailto:felix.schmitt-koopmann@zhaw.ch">felix.schmitt-koopmann@zhaw.ch</a><br/>
        <br/>
        <a href="https://www.zhaw.ch/en/about-us/person/dvya/">Prof. Dr. Alireza Darvishy</a><br/>
        Steinberggasse 13<br/>
        8400 Winterthur<br/>
        +41 (0) 58 934 69 12<br/>
        <a href="mailto:alireza.darvishy@zhaw.ch">alireza.darvishy@zhaw.ch</a>
    </>
}
