import React from "react";
import {Link} from "react-router-dom";

const Step1 = () => {
    return <div className="instructionsText">
        <Link to="/help#whatIsARegion" target={"_blank"}>More information about regions</Link>.
        <ul>
            <li><strong>No boxes/regions are highlighted:</strong> Select <em>Detect Regions</em> and refine the automatically detected regions</li>
            <li><strong>Delete all regions of a page:</strong> Select <em>Delete All</em> to delete all Regions of the current page</li>
            <li><strong>Changing region size:</strong> Select the region in the Page View and change the box size via the 8 resize points in the Page View</li>
            <li><strong>Changing region type:</strong> Select the region in the Page View and change the type via the dropdown menu in the blue box</li>
            <li><strong>Delete region:</strong> Select the region in the Page View and select <em>Delete Region</em></li>
            <li><strong>Combine regions:</strong> Select multiple regions with <em>Multi-Select</em> and click <em>Combine Regions</em>.</li>
            <li><strong>Separate multi-page regions:</strong> Select the region in the Page View and select <em>Separate Regions</em></li>
        </ul>
    </div>
}

export default Step1;
