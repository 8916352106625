import React, {useEffect, useRef, useState} from 'react';
import {useQuery, useMutation, useQueryClient} from "@tanstack/react-query";
import {getPageImagePdfJs, getTagType, updateTags} from "./APIcalls";
import Button from "react-bootstrap/Button";
import {headingColors} from "./Config";
import Dropdown from "react-bootstrap/Dropdown";
import {changeTagType, navigation} from "./Tools";
import DropdownButton from "react-bootstrap/DropdownButton";
import {drawFigures, drawHeadings, loadingCanvas} from "./Drawing";
import {checkHeadingLevels, detectHeadingLevels, getLastHeadingLevel} from "./utils/UtilsHeadings.jsx";
import Instructions from "./utils/Instructions";
import {errorDrawing} from "./utils/ErrorMessages";
import {MdOutlineAutoFixHigh} from "react-icons/md";
import {resizingX, startResizeX} from "./utils/UtilsResize";
import PageView from "./PageView";
import StepNavigationButtons from "./StepNavigationButtons";

const Headings = ({pdf, pdfInfo, stepSelected, setStep, menuSize, setMenuSize, showPageMenu, setShowPageMenu, setStepSelected}) => {

    const headings = useRef(false);
    const pageNum = useRef(1);
    const [imageReady, setImageReady] = useState(true);
    const modifications = useRef([]);

    // canvas
    const canvasRef = useRef(null);
    const ctx = useRef(null);
    const highlighted = useRef(false);

    const [renderI, setRenderI] = useState(0);
    const [detected, setDetected] = useState(false);
    const [showLabels, setShowLabels] = useState(true);

    const queryClient = useQueryClient();

    const viewed = useRef(Array.from({length: pdfInfo.numberOfPages}, _ => false));

    const [zoomFactor, setZoomFactor] = useState(1);

    // resize start values
    const startResizeValueMenu = useRef(null);

    useEffect(() => {
        if (canvasRef.current) {
            ctx.current = canvasRef.current.getContext('2d');
        }
        headingsQuery.refetch();
        viewed.current[pageNum.current - 1] = true;
    }, []);

    // changing step
    useEffect(() => {
        if (stepSelected !== -1) {
            updateStep(() => setStep(stepSelected));
        }
    }, [stepSelected]);

    // getting the image
    const headingsQuery = useQuery({
        queryKey: ["Headings", pdfInfo.fileid],
        queryFn: () => getTagType({pdfInfo: pdfInfo, tagType: "Headings", pdf: pdf}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: data => {
            headings.current = checkHeadingLevels({headings: data, modifications: modifications.current});
            setRenderI(prevState => prevState + 1);
        }
    });

    // getting the image
    const pageImage = useQuery({
        queryKey: ["image", pdfInfo.fileid, pageNum.current],
        queryFn: () => getPageImagePdfJs({pdf: pdf, pageNum: pageNum.current, setImageReady: setImageReady}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: () => drawing()
    });

    // update and get structTree
    const headingsMutation = useMutation({
        mutationFn: ({pdfInfo, modifications}) => updateTags({pdfInfo: pdfInfo, modifications: modifications})
    })

    function getHeadingList() {
        return headings.current.filter(elem => {
            // filter out connected elements
            if (elem.connectedElements.length > 0) {
                return elem.uuid === elem.connectedElements[0].uuid;
            }
            return true;
        }).map((heading, i) => {
            let headingLevel = 0;
            if (heading.type.length === 2) {
                headingLevel = parseInt(heading.type[1]);
            }
            let headingText = heading.text;
            return <li id="headingElement" className="d-flex flex-row align-items-center"
                       style={{marginLeft: headingLevel > 0 ? (headingLevel - 1)*50 + "px" : "0", borderColor: headingColors[heading.type]}} key={i}
                       onClick={() => {
                highlighted.current = heading;
                pageNum.current = heading.rectangle.page;
                setRenderI(prevState => prevState + 1);
            }}>
                <DropdownButton id="dropdown-headings" title={headingLevel > 0 ? "H" + headingLevel : "Title"} variant="light" key="tagTypeDropdown">
                    <Dropdown.Item active={headingLevel === 0}
                                   onClick={() => {
                                       // change title to h1 if already exists
                                       headings.current.forEach(heading => {
                                           if (heading.type === "Title") {
                                               changeTagType({
                                                   structTree: headings.current,
                                                   selectedStructElem: heading,
                                                   modifications: modifications.current,
                                                   newType: "H1"});
                                           }
                                       })
                                       changeTagType({
                                           structTree: headings.current,
                                           selectedStructElem: heading,
                                           modifications: modifications.current,
                                           newType: "Title"});

                                       // handle connected elements
                                       if (heading.connectedElements.length > 0) {
                                           heading.connectedElements.forEach(elem => {
                                               changeTagType({
                                                   structTree: headings.current,
                                                   selectedStructElem: elem,
                                                   modifications: modifications.current,
                                                   newType: "Title"});
                                           });
                                       }

                                       checkHeadingLevels({
                                           headings: headings.current,
                                           modifications: modifications.current});
                                       setRenderI(prevState => prevState + 1);
                                   }} key={"Title"}>
                        {"Title"}
                    </Dropdown.Item>
                    {Array.from(
                        {length: getLastHeadingLevel({headings: headings.current, selectedHeading: heading}) + 1},
                        (_, index) => index + 1
                    ).map((t, i) => {
                        return <Dropdown.Item active={headingLevel === t}
                                              onClick={() => {
                                                  changeTagType({
                                                      structTree: headings.current,
                                                      selectedStructElem: heading,
                                                      modifications: modifications.current,
                                                      newType: "H"+ t});

                                                  // handle connected elements
                                                  if (heading.connectedElements.length > 0) {
                                                      heading.connectedElements.forEach(elem => {
                                                          changeTagType({
                                                              structTree: headings.current,
                                                              selectedStructElem: elem,
                                                              modifications: modifications.current,
                                                              newType: "H"+ t});
                                                      });
                                                  }

                                                  checkHeadingLevels({
                                                      headings: headings.current,
                                                      modifications: modifications.current});
                                                  setRenderI(prevState => prevState + 1);
                                              }} key={i}>
                            {"H"+ t}
                        </Dropdown.Item>
                    })}
                </DropdownButton>
                <p id="headingText">{headingText}</p>
            </li>
        })

    }

    function changePage(i) {
        if (i !== pageNum.current - 1) {
            pageNum.current = i + 1;
            setRenderI(prevState => prevState + 1);
        }
    }

    /**
     * Helper function for updating the step
     */
    function updateStep(onSuccessFunction) {
        return headingsMutation.mutate({pdfInfo: pdfInfo, modifications: modifications.current}, {
            onSuccess: () => {
                queryClient.invalidateQueries(["Headings", pdfInfo.fileid]).then(() => {
                    if (onSuccessFunction != null) {
                        onSuccessFunction();
                    }
                });
            }
        });
    }


    /**
     * helper function for drawing
     */
    function drawing() {
        if (!headingsQuery.isLoading && imageReady && headings.current.length > 0 && ctx.current != null && pageImage.data != null) {
            try {
                canvasRef.current.height = pageImage.data.naturalHeight;
                canvasRef.current.width = pageImage.data.naturalWidth;
                drawHeadings({
                    ctx: ctx.current,
                    image: pageImage.data,
                    headings: headings.current,
                    page: pageNum.current,
                    highlighted: highlighted.current,
                    showHeadingLabels: showLabels
                });
            }
            catch (e) {
                errorDrawing({errorMessage: e});
            }
        }
        else if (headingsQuery.isLoading || pageImage.isLoading) {
            loadingCanvas({ctx: ctx.current, canvasRef: canvasRef.current});
        }
        else if (imageReady && pageImage.data != null && ctx.current != null) {
            drawFigures({ctx: ctx.current, figure: null, image: pageImage.data});
        }
    }

    drawing();

    return (
        <div className="workspaceArea"
             onMouseUp={() => startResizeValueMenu.current = null}
             onMouseMove={(e) => resizingX(e, startResizeValueMenu.current, setMenuSize)}
             style={startResizeValueMenu.current != null ? {userSelect: 'none'}: null}>
            <div id="menu" className="menu" style={{width: menuSize + "px"}}>
                <div className="resizeHandleX" onMouseDown={(e) => startResizeValueMenu.current = startResizeX(e)}></div>
                <Instructions
                    title="Step 3: Organize Heading Levels"
                    step={3}
                />
                {navigation({
                    callback: changePage,
                    length: pdfInfo.numberOfPages,
                    i: pageNum.current - 1,
                    title: "Pages",
                    viewed: []
                })}
                <div className="buttonRow">
                    <Button variant="light" onClick={() => {
                        detectHeadingLevels({headings: headings.current, modifications: modifications.current});
                        setDetected(() => true);
                    }} disabled={detected} className="actionButton">
                        <MdOutlineAutoFixHigh size="1.5em"/><br/>
                        {detected ? "Heading Levels Detected" : "Detect Heading Levels"}
                    </Button>
                </div>
                <div id="headingList" className="blueBox">
                    <h3>Heading Structure</h3>
                    {headings.current.length > 0 ? <div id="headingView">
                        <ul>
                            {getHeadingList()}
                        </ul>
                    </div> : null}
                </div>
                <StepNavigationButtons nextStep={() => setStepSelected(4)} previousStep={() => setStepSelected(2)}/>
            </div>
            <PageView
                pageNum={pageNum.current}
                canvasRef={canvasRef}
                showHeadingLabels={showLabels}
                setShowHeadingLabels={setShowLabels}
                zoomFactor={zoomFactor}
                setZoomFactor={setZoomFactor}
                isLoading={headingsQuery.isLoading || pageImage.isLoading}
                showPageMenu={showPageMenu}
                setShowPageMenu={setShowPageMenu}
                pdf={pdf}
                pdfInfo={pdfInfo}
                updateStep={updateStep}
            />
        </div>
    );
};

export default Headings;
