import React from "react";
import {Link} from "react-router-dom";

const Step5 = () => {
    return <div className="instructionsText">
        <Link to="/help#whyDoIHaveToDefineTheListStructure" target={"_blank"}>More information about list</Link>.
        <ul>
            <li><strong>Add new list items:</strong> Draw lines in the page view to separate list items. You can see the
                current list structure in the blue box.</li>
            <li><strong>Delete list items:</strong> Select the line you want to delete in the page view and click
                <em>Combine List Items</em></li>
            <li><strong>Delete the existing list structure:</strong> Select <em>Delete All List Items</em> to start from
                scratch with the list structure.</li>
        </ul>
    </div>
}

export default Step5;
