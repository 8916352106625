import React from 'react';
import Nav from 'react-bootstrap/Nav';
import {
    AiOutlineUpload,
    AiOutlineTable,
    AiOutlinePicture,
    AiOutlineUnorderedList,
    AiOutlineDownload
} from 'react-icons/ai';
import {TbMath} from 'react-icons/tb';
import {BiBookReader} from 'react-icons/bi';
import {BsTextareaT} from 'react-icons/bs'
import {FaStream} from 'react-icons/fa';

const NavBar = ( {task_i, tasks, setTask}) => {
    return (
        <Nav className="flex-column" id="navbar">
            <Nav.Link onClick={() => setTask(0)} aria-disabled={false} id={task_i===0 ? 'taskSelected': task_i>0 ? 'taskDone': null} aria-label="upload a PDF"><AiOutlineUpload size={50} /></Nav.Link>
            <Nav.Link onClick={() => setTask(1)} disabled={task_i===0} aria-disabled={task_i===0} id={task_i===1 ? 'taskSelected': task_i>1 ? 'taskDone': null} aria-label="Step 1: Regions"><BsTextareaT size={50} /></Nav.Link>
            <Nav.Link onClick={() => setTask(2)} disabled={task_i < 1} aria-disabled={task_i < 1} id={task_i===2 ? 'taskSelected': task_i>2 ? 'taskDone': null} aria-label="Step 2: Reading Order"><BiBookReader size={50} /></Nav.Link>
            <Nav.Link onClick={() => setTask(3)} disabled={task_i < 2} aria-disabled={task_i < 2} id={task_i===3 ? 'taskSelected': task_i>3 ? 'taskDone': null} aria-label="Step 3: Heading Levels"><FaStream size={50} /></Nav.Link>
            <Nav.Link onClick={() => setTask(4)} disabled={task_i < 3} aria-disabled={task_i < 3} id={task_i===4 ? 'taskSelected': task_i>4 ? 'taskDone': null} aria-label="Step 4: Tables"><AiOutlineTable size={50} /></Nav.Link>
            <Nav.Link onClick={() => setTask(5)} disabled={task_i < 4} aria-disabled={task_i < 4} id={task_i===5 ? 'taskSelected': task_i>5 ? 'taskDone': null} aria-label="Step 5: Lists"><AiOutlineUnorderedList size={50} /></Nav.Link>
            <Nav.Link onClick={() => setTask(6)} disabled={task_i < 5} aria-disabled={task_i < 5} id={task_i===6 ? 'taskSelected': task_i>6 ? 'taskDone': null} aria-label="Step 6: Figures"><AiOutlinePicture size={50} /></Nav.Link>
            <Nav.Link onClick={() => setTask(7)} disabled={task_i < 6} aria-disabled={task_i < 6} id={task_i===7 ? 'taskSelected': task_i>7 ? 'taskDone': null} aria-label="Step 7: Formulas"><TbMath size={50} /></Nav.Link>
            <Nav.Link onClick={() => setTask(8)} disabled={task_i < 7} aria-disabled={task_i < 7} id={task_i===8 ? 'taskSelected': task_i>8 ? 'taskDone': null} aria-label="Step 8: Meta Information"><AiOutlineDownload size={50} /></Nav.Link>
        </Nav>
    );
};

export default NavBar;
