import {canvasSize, colors, readingOrderConfig} from "../Config";
import {transformRectangle} from "../Tools";

function getReadingOrderByPoint({structTree, point, imageSize}) {
    const possibleStructElemTypes = Object.keys(colors);
    let foundStructElem = null;
    structTree.some(structElem => {
        if (structElem.type === "MCRef") {
            return false;
        }
        const rect = transformRectangle({rect: structElem.rectangle, imageSize: imageSize});
        const threshold = {x: rect.w * 0.1, y: rect.h * 0.1}
        if (rect.x + threshold.x <= point.x && point.x <= rect.x + rect.w - threshold.x &&
            rect.y + threshold.y <= point.y && point.y <= rect.y + rect.h - threshold.y) {
            if (foundStructElem == null ||
                (structElem.rectangle.urx - structElem.rectangle.llx) *
                (structElem.rectangle.ury - structElem.rectangle.lly) <
                (foundStructElem.rectangle.urx - foundStructElem.rectangle.llx) *
                (foundStructElem.rectangle.ury - foundStructElem.rectangle.lly)) {
                foundStructElem = structElem;
                return true;
            }
        }
        return false;
    });
    return foundStructElem;
}

function getReadingOrder({structElem, readingOrder}) {
    structElem.forEach(elem => {
        if (elem.type in colors) {
            readingOrder.push(elem)
        }
        if (elem.children !== null) {
            getReadingOrder({structElem: elem.children, readingOrder: readingOrder});
        }
    })
    return readingOrder;
}

function getReadingOrderElement({readingOrder, point}) {
    let foundElement = false;
    if (readingOrder === null || readingOrder.length === 0) return false;
    for (let i = 0; i < readingOrder.length; i++) {
        const rect = {
            x: readingOrderConfig.listElemHeight,
            y: (readingOrderConfig.listElemHeight + readingOrderConfig.listElemDistance) * i,
            w: canvasSize[0] - 2 * readingOrderConfig.listElemHeight,
            h: readingOrderConfig.listElemHeight
        };
        if (rect.x <= point.x && point.x <= rect.x + rect.w &&
            rect.y <= point.y && point.y <= rect.y + rect.h) {
            foundElement = i;
        }
    }
    ;
    return foundElement;
}

export {getReadingOrderElement, getReadingOrder, getReadingOrderByPoint};
