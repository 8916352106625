import {BiHide, BiShow} from "react-icons/bi";
import Button from "react-bootstrap/Button";
import React from "react";


const ShowLabel = ({setShowLabels, showLabels, elementName}) => {
    return <Button variant="light" active={!showLabels} onClick={() => {
        setShowLabels((prevState) => {return !prevState});
    }} className="pageViewButtons">
        {showLabels ? <><BiHide /><br/> Hide {elementName}</> : <><BiShow /><br/> Show {elementName}</>}
    </Button>
}
export default ShowLabel;

