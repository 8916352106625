import axios from "axios";
import {processPdfJSRenderingInformation} from "./utils/ProcessPdfJSRenderingInformation";
import {imageScaling} from "./Config";
import * as pdfjsLib from 'pdfjs-dist/webpack.mjs';


export function getPageImagePdfJs({pdf, pageNum, setImageReady}) {
    const scale = imageScaling;
    setImageReady(false);
    let img=new Image();
    img.onload = () => {
        setImageReady(true);
    }
    return pdf.getPage(pageNum).then((page) => {
        let viewport = page.getViewport({scale: scale});
        let canvas = document.createElement('canvas'), ctx = canvas.getContext('2d');
        let renderContext = {canvasContext: ctx, viewport: viewport};

        canvas.height = Math.abs(viewport.height);
        canvas.width = Math.abs(viewport.width);

        const pageCanvas = page.render(renderContext)
        return pageCanvas.promise.then(() => {
            img.src = canvas.toDataURL();
            canvas.remove();
            return img;
        });
    });
}

function postPdfJSRenderingInformation({pdfInfo, pageNum, pdfJSRenderingInformation}) {
    return axios.post("api/files/" + pdfInfo.fileid + "/pages/" + pageNum + "/processPdfJSRendering", JSON.stringify(pdfJSRenderingInformation), {
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        return res.data;
    });
}

export function getMetaData({pdfInfo}) {
    return axios.get("api/files/" + pdfInfo.fileid + "/metadata").then(res => {
        return res.data;
    });
}

export function updateMetaData({fileid, documentInfo}) {
    return axios.put("api/files/" + fileid + "/metadata", JSON.stringify(documentInfo), {
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export function getPDF({pdfInfo, pdf, addWhitespace}) {
    return axios.post("api/files/" + pdfInfo.fileid + "/addWhitespace/" + addWhitespace + "/download", {}, {
        responseType: "blob"
    }).then(res => {
        // not all pages are processed because PDFJSRendering information are missing
        if (res.status === 201) {
            // if pdf object is null
            if (pdf == null) {
                return getPDF({pdfInfo: pdfInfo, pdf: null, addWhitespace: false}).then(res => {
                    const url = URL.createObjectURL(res.data);
                    return pdfjsLib.getDocument(url).promise.then(pdf => {
                        return getPDF({pdfInfo: pdfInfo, pdf: pdf, addWhitespace: true});
                    });
                });
            }
            return res.data.text().then(t => {
                return Promise.all(JSON.parse(t).map(pageNum => {
                    return processPdfJSRenderingInformation({
                        pdf: pdf,
                        pageNum: pageNum
                    }).then(pdfJSRenderingInformation => {
                        return postPdfJSRenderingInformation({
                            pdfInfo: pdfInfo,
                            pageNum: pageNum,
                            pdfJSRenderingInformation: pdfJSRenderingInformation
                        });
                    });
                })).then(() => getPDF({pdfInfo: pdfInfo, pdf: pdf, addWhitespace: addWhitespace}));
            });
        }
        else {
            return res;
        }
    }).catch((res) => {
        console.error("Could not Download the PDF from the backend.", res);
    });
}

export function analyzePDF({pdfInfo}) {
    return axios.post("api/files/" + pdfInfo.fileid + "/analyzePDF", {}).then(res => {
        return res;
    }).catch((response) => {
        console.error("Could not move PDF to analyze folder", response);
    });
}

export function getPageStructTree({pdfInfo, pageNum, addArtifacts, pdf}) {
    return axios.get("api/files/" + pdfInfo.fileid + "/pages/" + pageNum + "/addArtifacts/" + addArtifacts + "/getPageStructTree").then(res => {
        if (res.status === 201) {
            return Promise.all(res.data.map(pageNum => {
                return processPdfJSRenderingInformation({
                    pdf: pdf,
                    pageNum: pageNum
                }).then(pdfJSRenderingInformation => {
                    return postPdfJSRenderingInformation({
                        pdfInfo: pdfInfo,
                        pageNum: pageNum,
                        pdfJSRenderingInformation: pdfJSRenderingInformation
                    });
                });
            })).then(() => getPageStructTree({pdfInfo: pdfInfo, pageNum: pageNum, addArtifacts: addArtifacts, pdf: pdf}));
        }
        return res.data;
    });
}

export function updateTags({pdfInfo, modifications}) {
    return axios.post("api/files/" + pdfInfo.fileid + "/updateTags", JSON.stringify(modifications), {
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export function getPageOperators({pdfInfo, pageNum}) {
    return axios.get("api/files/" + pdfInfo.fileid + "/pages/" + pageNum + "/getPageOperators").then(res => {
        return res.data
    })
}

export function getStructTree({pdfInfo, pdf, pageNum}) {
    return getPageStructTree({pdfInfo, pageNum: pageNum, addArtifacts: true, pdf: pdf}).then(structTree => {
            return getPageOperators({pdfInfo: pdfInfo, pageNum: pageNum}).then(operators => {
                return {operators: operators, structTree: structTree};
            });
        });
}

export function updatePageStructTree({pdfInfo, oldPage, newPage, modifications, addArtifacts, pdf}) {
    return axios.post("api/files/" + pdfInfo.fileid + "/pages/" + oldPage + "/updatePageStructTree", JSON.stringify(modifications), {
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res_update => {
        return getPageStructTree({pdfInfo, pageNum: newPage, addArtifacts: addArtifacts, pdf: pdf}).then(structTree => {
            return getPageOperators({pdfInfo: pdfInfo, pageNum: newPage}).then(operators => {
                    return {operators: operators, structTree: structTree};
            });
        });
    });
}

export function postPDF({file}) {
    return axios.post("api/files", file, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }).then(res => {
        if (res.data.message === 'The file exceeds the file size limit.') {
            return Promise.reject("The file exceeds the size limit")
        }
        const pdfInfo = {fileid: res.data.message}
        return axios.get("api/files/" + pdfInfo.fileid + "/info").then(
            res => {
                pdfInfo.filename = res.data.filename;
                pdfInfo.numberOfPages = res.data.numberOfPages;
                return pdfInfo;
            });
    })
}

export function getFileInfo({file}) {
    const pdfInfo = {fileid: file.id};
    return axios.get("api/files/" + pdfInfo.fileid + "/info").then(
        res => {
            pdfInfo.filename = res.data.filename;
            pdfInfo.numberOfPages = res.data.numberOfPages;
            return pdfInfo;
        });
}

export function getTagType({pdfInfo, tagType, pdf}) {
    return axios.get("api/files/" + pdfInfo.fileid + "/getTagType/" + tagType).then(res => {
        if (res.status === 201) {
            return Promise.all(res.data.map(pageNum => {
                return processPdfJSRenderingInformation({
                    pdf: pdf,
                    pageNum: pageNum
                }).then(pdfJSRenderingInformation => {
                    return postPdfJSRenderingInformation({
                        pdfInfo: pdfInfo,
                        pageNum: pageNum,
                        pdfJSRenderingInformation: pdfJSRenderingInformation
                    });
                });
            })).then(() => getTagType({pdfInfo: pdfInfo, tagType: tagType, pdf: pdf}));
        }
        return res.data;
    });
}

export function predictRegions({pdfInfo, page}) {
    return axios.post("api/files/" + pdfInfo.fileid + "/page/" + page + "/predictRegions").then(res => {
        return res;
    });
}

export function predictFormula({pdfInfo, formulaId}) {
    return axios.post("api/files/" + pdfInfo.fileid + "/formulaId/" + formulaId + "/predictFormula").then(res => {
        return res;
    });
}

export function getFiles() {
    return axios.get("api/files").then(res => {
        return res.data;
    });
}

export function deleteFile({fileId}) {
    return axios.delete("api/files/" + fileId).then(res => {
        return res;
    });
}

export function registerNewsletter({email}) {
    return axios.post("api/registerNewsletter", JSON.stringify(email.replace('"', '')), {
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        return res;
    });
}
