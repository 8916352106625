import React from "react";
import {Link} from "react-router-dom";

const Step7 = () => {
    return <div className="instructionsText">
        <Link to="/help#whyDoIHaveToWriteAnAlternativeTextForFormulas" target={"_blank"}>More information
        about alternative text of mathematical formulas</Link>.
        <ul>
            <li><strong>Wrong or empty alternative text:</strong> Select <em>Recognize Formula</em> to generate automatically
            the alternative text. Correct the formula in the Math Editor by modifying the LaTeX code. To generate the corresponding alternative text select <em>Convert</em></li>
            <li><strong>Minor alternative text errors:</strong> Modify the alternative text directly.</li>
        </ul>
    </div>
}

export default Step7;
