import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function BasicButtonExample( {language, changeLanguage}) {
    return (
            <DropdownButton id="dropdown-basic-button" title={language}>
                <Dropdown.Item onClick={() => changeLanguage("EN")}>EN</Dropdown.Item>
            </DropdownButton>
    );
}

export default BasicButtonExample;
