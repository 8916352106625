import React, {useState} from 'react';
import Button from "react-bootstrap/Button";
import {FaArrowLeft, FaArrowRight} from "react-icons/fa";

const StepNavigationButtons = ({nextStep, previousStep}) => {
    return <>
        <div className="flex-fill">

        </div>
        <div className="buttonRow">
            <Button className="instructionsButton" onClick={() => previousStep()} variant="light">
                <FaArrowLeft /> Previous Step
            </Button>
            {nextStep != null ? <Button className="instructionsButton" onClick={() => nextStep()} variant="light">
                Next Step <FaArrowRight />
            </Button> : null}
        </div>
    </>

}

export default StepNavigationButtons;
