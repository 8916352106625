import React from "react";
import Button from "react-bootstrap/Button";
import {BiZoomIn, BiZoomOut} from "react-icons/bi";


const ZoomNav = ({setZoomFactor}) => {
    return <div className="d-flex flex-row pageViewButtons">
        <Button onClick={() => setZoomFactor(prevState => Math.min(prevState * 1.2, 3))} variant="light" id="zoomInButton"><BiZoomIn /> <br /> Zoom In</Button>
        <Button onClick={() => setZoomFactor(prevState => Math.max(prevState / 1.2, 0.4))} variant="light"><BiZoomOut /> <br /> Zoom Out</Button>
    </div>
}
export default ZoomNav;
