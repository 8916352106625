import {transformRectangle, mode} from "../Tools";
import {newTag} from "../StructTreeActions";

function getTableDimensions({table, imageSize}) {
    let rowDimensions = [];
    let columnDimensions = [];
    table.processedTable.forEach((row, rowI) => {
        rowDimensions.push({top: 9999999, bottom: 0});
        row.forEach((column, columnI) => {
            if (columnDimensions[columnI] == null) columnDimensions[columnI] = {left: 9999999, right: 0};
            column.elements.forEach(e => {
                const rect = transformRectangle({rect: e.rectangle, imageSize: imageSize});
                if (rect.x < columnDimensions[columnI].left) columnDimensions[columnI].left = rect.x;
                if (rect.x + rect.w > columnDimensions[columnI].right) columnDimensions[columnI].right = rect.x + rect.w;
                if (rect.y < rowDimensions[rowI].top) rowDimensions[rowI].top = rect.y;
                if (rect.y + rect.h > rowDimensions[rowI].bottom) rowDimensions[rowI].bottom = rect.y + rect.h;
            })
        })
    })
    // columnDimensionCheck
    columnDimensions.forEach((columnDimension, i) => {
        if (columnDimension.right === 0) {
            // search forward
            let replaceI = i + 1;
            while (replaceI < columnDimensions.length && columnDimension.right === 0) {
                if (columnDimensions[replaceI].right !== 0) {
                    columnDimension.left = columnDimensions[replaceI].left;
                    columnDimension.right = columnDimensions[replaceI].left;
                }
                replaceI++;
            }
            // search backward
            if (columnDimension.right === 0) {
                replaceI = i - 1;
                while (replaceI >= 0 && columnDimension.right === 0) {
                    if (columnDimensions[replaceI].right !== 0) {
                        columnDimension.left = columnDimensions[replaceI].right;
                        columnDimension.right = columnDimensions[replaceI].right;
                    }
                    replaceI--;
                }
            }
        }
    })
    return [rowDimensions, columnDimensions];
}

function fixTableDimensions(table) {
    let tableRowMax = 0;
    table.forEach(row => {
        if (tableRowMax < row.length) tableRowMax = row.length;
    });
    return table.map(row => {
        if (tableRowMax > row.length) {
            const type = row[row.length - 1].type;
            const connectFirstRow = row[row.length - 1].connectFirstRow;
            const connectLastRow = row[row.length - 1].connectLastRow;
            while (row.length < tableRowMax) {
                row.push({elements:[], type: type, connectFirstRow: connectFirstRow, connectLastRow: connectLastRow});
            }
        }
        return row;
    });
}

function prepareTable({tableArray, elements, modifications}) {
    let tableCell = [];
    let tableRow = [];
    let artifacts = [];
    elements.forEach(element => {
        if ((element.text === "f*" || element.text === " " || element.text === "SOperator") && element.type === "MCRef") {
            artifacts.push({uuid: element.uuid});
            return;
        }
        if (element.type === "TR") {
            if (tableRow.length > 0) {
                tableArray.push(tableRow);
                tableRow = [];
            }
            tableArray.push(prepareTable({tableArray: [], elements: element.children, modifications: modifications})[0])
        }
        else if (element.type === "TD") {
            if (tableCell.length > 0) {
                tableRow.push({type: "TD", elements: tableCell});
                tableCell = [];
            }
            tableRow.push({type: "TD", elements: element.children})
        }
        else if (element.type === "TH") {
            if (tableCell.length > 0) {
                tableRow.push({type: "TD", elements: tableCell});
                tableCell = [];
            }
            tableRow.push({type: "TH", elements: element.children})
        }
        else {
            tableCell.push(element)
        }
    })
    if (tableCell.length > 0) {
        tableRow.push({type: "TD", elements: tableCell});
    }
    if (tableRow.length > 0) {
        tableArray.push(tableRow);
    }
    if (artifacts.length > 0) {
        newTag({elem: {type: "Artifact", children: artifacts}, modifications: modifications, structTree: []});
    }
    if (mode(tableArray.map(column => column.type)) !== "TH" && mode(tableArray.map(column => column[0].type)) !== "TH") {
        tableArray.forEach(column => column.type = "TH");
    }
    return tableArray;
}

function connectRows(table) {
    if (table.connectedElements.length < 2) {
        return;
    }
    let lastRow = {uuid: null};
    table.connectedElements.forEach((connectedTable, connectedTableI) => {
        connectedTable.connectFirstRow = false;
        connectedTable.connectLastRow = false;
        const rowTable = connectedTable.children.filter(row => row.type === "TR");
        if (rowTable.length > 0 && rowTable[0].connectedElements.length > 0 && rowTable[0].connectedElements.map(e => e.uuid).includes(lastRow.uuid)) {
            connectedTable.connectFirstRow = true;
            table.connectedElements[connectedTableI - 1].connectLastRow = true;
        }
        lastRow = rowTable[rowTable.length - 1];
    });
}

function checkHeaderCells({processedTable}) {
    let rowHeader = mode(processedTable[0].map(column => column.type));
    let columnHeader = mode(processedTable.map(column => column[0].type));
    processedTable.forEach((row, rowI) => {
        row.forEach((column, columnI) => {
            if ((rowI === 0 && rowHeader === "TH") || (columnI === 0 && columnHeader === "TH")) {
                column.type = "TH";
            }
            else {
                column.type = "TD";
            }
        });
    });
}


function getProcessedTable(table) {
    // handling of connected tables
    if (table.connectedElements.length > 0) {
        let tableCombined = []
        for (let tableI = 0; tableI < table.connectedElements.length; tableI++) {
            // combine cells if needed
            if (tableI !== 0) {
                const connect = tableI !== 0 && table.connectedElements[tableI].connectFirstRow;
                for (let cellI = 0; cellI < tableCombined[tableCombined.length - 1].length; cellI++) {
                    tableCombined[tableCombined.length - 1][cellI].connectLastRow = connect;
                }
                for (let cellI = 0; cellI < table.connectedElements[tableI].processedTable[0].length; cellI++) {
                    table.connectedElements[tableI].processedTable[0][cellI].connectFirstRow = connect;
                }
            }
            tableCombined = tableCombined.concat(table.connectedElements[tableI].processedTable);
        }
        tableCombined = fixTableDimensions(tableCombined);
        return tableCombined;
    }
    else {
        return table.processedTable;
    }
}

function removeEmptyRow(processedTable) {
    // remove empty rows
    let newProcessedTable = [];
    processedTable.forEach(row => {
        if(!row.every(cell => cell.elements.length === 0)) {
            newProcessedTable.push(row);
        }
    });
    return newProcessedTable;
}

function getEmptyColumns(processedTable) {
    let keepColumns = []
    for (let columnI = 0; columnI < processedTable[0].length; columnI++) {
        if (!processedTable.every(row => row[columnI].elements.length === 0)) {
            keepColumns.push(columnI);
        }
    }
    return keepColumns;
}

function removeEmptyColumns(processedTable, keepColumns) {
    return processedTable.map(row => {
        let newRow = [];
        row.forEach((cell, columnI) => {
            if (keepColumns.includes(columnI)) {
                newRow.push(cell);
            }
        });
        return newRow;
    });
}

function removeEmptyRowsColumns(table) {
    if (table.connectedElements.length > 1) {
        table.connectedElements.forEach(t => {
            t.processedTable = removeEmptyRow(t.processedTable);
        })
        const keepColumns = getEmptyColumns(getProcessedTable(table));
        table.connectedElements.forEach(t => {
            t.processedTable = removeEmptyColumns(t.processedTable, keepColumns);
        })
    }
    else {
        table.processedTable = removeEmptyRow(table.processedTable);
        const keepColumns = getEmptyColumns(getProcessedTable(table));
        table.processedTable = removeEmptyColumns(table.processedTable, keepColumns);
    }
}

export {getTableDimensions, prepareTable, checkHeaderCells, fixTableDimensions, getProcessedTable, removeEmptyRowsColumns, connectRows};
