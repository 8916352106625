import React, {useEffect, useState} from 'react';

function InfoLayout({content}) {
    const [headingElements, setHeadingElements] = useState([]);
    const [visible, setVisible] = useState(null);


    useEffect(() => {
        setHeadingElements(Array.from(document.querySelectorAll("h3")));
        checkVisibleHeadings();
    }, []);

    function checkVisibleHeadings() {
        headingElements.every(heading => {
            const rect = heading.getBoundingClientRect();
            const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
            if (!(rect.bottom < 0 || rect.top - viewHeight >= 0)) {
                setVisible(heading.id);
                return false;
            }
            return true;
        });
    }

    return (
        <div className="infoPage">
            <div className="tableOfContent">
                <h2>Table of Content</h2>
                <ul>
                    {headingElements.map(heading=> <li key={heading.id}>
                        <a href={`#${heading.id}`} onClick={(e) => {
                            e.preventDefault();
                            document.querySelector(`#${heading.id}`).scrollIntoView({
                                behavior: "smooth"
                            });
                        }} className={heading.id === visible ? "activeTableOfContent" : ""}>{heading.innerText}</a>
                    </li>)}
                </ul>
            </div>
            <div className="infoContent" onScroll={checkVisibleHeadings}>
                {content}
            </div>
        </div>
    );
}

export default InfoLayout;
