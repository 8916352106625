import {getBoundingBoxCell, transformRectangle} from "../Tools";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import React from "react";
import {v4 as uuidv4} from "uuid";

/*
li = {
        lblChildren: [],
        lBodyChildren: [],
        sublist: [],
        uuid: listItem.uuid,
        connectedElements: listItem.connectedElements,
        baseListUuid: uuid
    }
 */
function processListItem(listItem, listUuid) {
    let li = {
        lblChildren: [],
        lBodyChildren: [],
        sublist: [],
        uuid: listItem.uuid,
        connectedElements: listItem.connectedElements.map(e => e.uuid),
        baseListUuid: listUuid
    }
    for (let childrenI = 0; childrenI < listItem.children.length; childrenI++) {
        // lbl
        if (listItem.children[childrenI].type === "Lbl") {
            li.lblChildren = li.lblChildren.concat(listItem.children[childrenI].children);
        }
        // LBody
        else if (listItem.children[childrenI].type === "LBody") {
            for (let lBodyI = 0; lBodyI < listItem.children[childrenI].children.length; lBodyI++) {
                // if nested List
                if (listItem.children[childrenI].children[lBodyI].type === "L") {
                    li.sublist = li.sublist.concat(processLists([listItem.children[childrenI].children[lBodyI]], false, [listUuid])[0]);
                }
                else {
                    li.lBodyChildren = li.lBodyChildren.concat(listItem.children[childrenI].children[lBodyI]);
                }
            }
        }
        // other elements add to lBody
        else {
            li.lBodyChildren = li.lBodyChildren.concat(listItem.children[childrenI]);
        }
    }
    return li;
}

function processLists(lists, connectElements=false, listUuid=[]) {
    let processedLists = [];
    // go through all lists
    lists.forEach((list, listI) => {
        // split list elements into listItems and other elements
        let processedList = [];
        let other = [];
        for (let childrenI = 0; childrenI < list.children.length; childrenI++) {
            if (list.children[childrenI].type === "LI") {
                if (other.length > 0) {
                    processedList.push(processListItem({children: other, uuid: uuidv4(), connectedElements: []}, listUuid[listI] == null ? list.uuid : listUuid[listI]));
                    other = [];
                }
                processedList.push(processListItem(list.children[childrenI], listUuid[listI] == null ? list.uuid : listUuid[listI]));
            }
            else {
                other.push(list.children[childrenI]);
            }
        }
        if (other.length > 0) {
            processedList.push(processListItem({children: other, uuid: uuidv4(), connectedElements: []}, listUuid[listI] == null ? list.uuid : listUuid[listI]));
        }
        processedLists.push({uuid: list.uuid, processedList: processedList, connectedElements: list.connectedElements.map(e => e.uuid)});
    });
    let combinedLists = [];
    for (let processedList of processedLists) {
        if (processedList.connectedElements.length > 0 && connectElements) {
            if (processedList.connectedElements[0] === processedList.uuid) {
                combinedLists.push(processedLists.filter(list => processedList.connectedElements.includes(list.uuid)).map(list => list.processedList).flat(1));
            }
        }
        else {
            combinedLists.push(processedList.processedList);
        }
    }
    return combinedLists;
}

function combineRectangles(rect1, rect2) {
    // if rect 1 is null
    if (rect1.page == null && rect2.page != null) {
        return rect2;
    }
    // if not same page
    if (rect1.page != null && rect2.page !== rect1.page) {
        return rect1;
    }
    // merge
    return {
        page: rect1.page,
        llx: Math.min(rect1.llx, rect2.llx),
        lly: Math.min(rect1.lly, rect2.lly),
        urx: Math.max(rect1.urx, rect2.urx),
        ury: Math.max(rect1.ury, rect2.ury)
    }
}

function getListItemRectangle(listItem) {
    let rectangle = {
        page: null,
        llx: null,
        lly: null,
        urx: null,
        ury: null
    };
    // go through the label elements
    for (let lblI = 0; lblI < listItem.lblChildren.length; lblI++) {
        rectangle = combineRectangles(rectangle, listItem.lblChildren[lblI].rectangle);
    }
    // go through the body elements
    for (let lBodyI = 0; lBodyI < listItem.lBodyChildren.length; lBodyI++) {
        rectangle = combineRectangles(rectangle, listItem.lBodyChildren[lBodyI].rectangle);
    }
    // go through the sublist elements
    return combineRectangles(rectangle, getListRectangle(listItem.sublist, null));
}

function getListRectangle(list, uuid) {
    let rectangle = {
        page: null,
        llx: null,
        lly: null,
        urx: null,
        ury: null
    };
    for (let listItemI = 0; listItemI < list.length; listItemI++) {
        if (uuid != null && list[listItemI].baseListUuid !== uuid) {
            continue;
        }
        rectangle = combineRectangles(rectangle, getListItemRectangle(list[listItemI]));
    }
    return rectangle;
}

function getBaseListUuids(list) {
    let uuids = [];
    for (let i = 0; i < list.length; i++) {
        if (!uuids.includes(list[i].baseListUuid)) {
            uuids.push(list[i].baseListUuid);
        }
    }
    return uuids;
}

function getFlatList(list) {
    let flatList = [];
    for (let listItemI = 0; listItemI < list.length; listItemI++) {
        if (list[listItemI].connectedElements.length < 2 || list[listItemI].connectedElements[0] === list[listItemI].uuid || list[listItemI].sublist.length === 0) {
            flatList.push(list[listItemI]);
        }
        if (list[listItemI].sublist.length > 0) {
            flatList = flatList.concat(getFlatList(list[listItemI].sublist));
        }
    }
    return flatList;
}

function removeConnectedElements(list, uuid) {
    for (let listItem of list) {
        if (listItem.uuid === uuid) {
            listItem.connectedElements = [];
            return true;
        }
        if (listItem.sublist.length > 0) {
            if (removeConnectedElements(listItem.sublist, uuid)) {
                listItem.connectedElements = [];
                return true;
            }
        }
    }
    return false;
}

function insertListItemAfter(list, listItem, uuidBefore) {
    for (let listItemI = 0; listItemI < list.length; listItemI++) {
        if (list[listItemI].uuid === uuidBefore) {
            list.splice(listItemI + 1, 0, listItem);
            return true;
        }
        if (list[listItemI].sublist.length > 0) {
            if (insertListItemAfter(list[listItemI].sublist, listItem, uuidBefore)) {
                return true;
            }
        }
    }
    return false;
}

function addListItem({list, startPos, curPos, imageSize, baseListUuid}) {
    // if start or stop is not defined
    if (startPos == null || curPos == null) {
        return false
    }

    const listRect = transformRectangle({rect: getListRectangle(list, baseListUuid), imageSize: imageSize});
    let flatList = getFlatList(list);
    let midY = (startPos.y + curPos.y) /2;

    // above list
    if (startPos.y < listRect.y && curPos.y < listRect.y && flatList[0].baseListUuid !== baseListUuid) {
        for (let listItemI = 0; listItemI < flatList.length; listItemI++) {
            if (flatList[listItemI].baseListUuid === baseListUuid) {
                const connectedElements = flatList[listItemI].connectedElements;
                connectedElements.forEach(connectedUuid => removeConnectedElements(list, connectedUuid));
            }
        }
    }

    let lastListItem = null;

    // go through the list
    for (let listItem of flatList) {
        if (listItem.baseListUuid !== baseListUuid) {
            continue;
        }
        lastListItem = listItem;
        const listItemRect = transformRectangle({rect: getListItemRectangle(listItem), imageSize: imageSize});
        if (midY > listItemRect.y && midY < listItemRect.y + listItemRect.h) {

            // create new list item
            let newListItem = {
                lblChildren: [],
                lBodyChildren: [],
                sublist: listItem.sublist,
                uuid: uuidv4(),
                connectedElements: [],
                baseListUuid: baseListUuid,
            };

            listItem.sublist = [];

            // if it is the first element of the connected elements
            if (listItem.connectedElements.length > 0 && listItem.connectedElements[0] === listItem.uuid) {
                newListItem.uuid = listItem.uuid;
                newListItem.connectedElements = listItem.connectedElements;
                listItem.uuid = uuidv4();
                listItem.connectedElements = [];
            }

            // split lbl elements
            let newLblChildren = [];
            listItem.lblChildren.forEach(lblE => {
                const lblEMidRect = transformRectangle({rect: lblE.rectangle, imageSize: imageSize});
                // above line
                if (lblEMidRect.y + lblEMidRect.h / 2 < midY) {
                    newLblChildren.push(lblE);
                }
                else {
                    newListItem.lblChildren.push(lblE);
                }
            });
            listItem.lblChildren = newLblChildren;

            // split lBody elements
            let newLBodyChildren = [];
            listItem.lBodyChildren.forEach(lBodyE => {
                const lblEMidRect = transformRectangle({rect: lBodyE.rectangle, imageSize: imageSize});
                // above line
                if (lblEMidRect.y + lblEMidRect.h / 2 < midY) {
                    newLBodyChildren.push(lBodyE);
                }
                else {
                    newListItem.lBodyChildren.push(lBodyE);
                }
            });
            listItem.lBodyChildren = newLBodyChildren;

            // insert new list
            insertListItemAfter(list, newListItem, listItem.uuid);
        }
    }

    // below list
    if (startPos.y > listRect.y + listRect.h && curPos.y > listRect.y + listRect.h && flatList[flatList.length - 1] !== lastListItem) {
        const connectedElements = lastListItem.connectedElements;
        connectedElements.forEach(connectedUuid => {
            let connectedElement = getListItemUuid(list, connectedUuid);
            if (connectedElement != null) {
                connectedElement.connectedElements = [];
            }
        });
    }
}

function updateListItem({listItem}) {
    listItem.text = listItem.children.map(MCRef => MCRef.text).join("");
    listItem.rectangle = getBoundingBoxCell({elements: listItem.children});
}

function getAllMCRefs({element}) {
    let MCRef = [];
    element.children.forEach(e => {
        if (e.type !== "MCRef") {
            MCRef = MCRef.concat(getAllMCRefs({element: e, MCRef: MCRef}));
        } else {
            MCRef.push(e);
        }
    })
    return MCRef;
}

function checkList({list, baseList}) {
    if (baseList == null) {
        baseList = list;
    }
    const correct = list.children.every(li => {
        if (li.type !== "LI") {
            return false;
        }
        li.baseList = baseList;
        return li.children.every(liE => {
            if (liE.type !== "LBody" && liE.type !== "Span" && liE.type !== "Lbl") {
                return false;
            }
            return liE.children.every(e => {
                if (e.type !== "MCRef" && e.type !== "L") {
                    return false;
                }
                if (e.type === "L") {
                    e = checkList({list: e, baseList: baseList});
                }
                return true;
            });
        });
    });
    if (!correct) {
        list = {
            type: "L",
            children: [{
                type: "LI",
                children: [{
                    type: "LBody",
                    children: getAllMCRefs({element: list})
                }],
                baseList: baseList
            }],
            id: list.uuid,
            connectedElements: list.connectedElements
        };
        updateList({list: list, positions: [0]})
    }
    return list;
}

function updateList({list, positions}) {
    for (let i = positions.length - 1; i >= 0; i--) {
        let sublist = getSublist({list: list, position: positions.slice(0, i + 1), i: 0, create: false});
        if (sublist.children.length === 0) {
            // remove empty list
            let parentList = getSublist({list: list, position: positions.slice(0, i), i: 0, create: false});
            parentList.children.forEach(li => li.children.forEach(liE => {
                let removeEmpty = [];
                liE.children.forEach((e, ei) => {
                    if (e.type === "L" && e.children.length === 0) {
                        removeEmpty.push(ei);
                    }
                })
                let correctionI = 0;
                removeEmpty.forEach(ei => {
                    liE.children.splice(ei - correctionI, 1);
                    correctionI = correctionI + 1;
                })
            }))
        } else {
            // update children
            sublist.children.forEach(liE => {
                liE.children.forEach(element => {
                    updateListItem({listItem: element});
                });
                updateListItem({listItem: liE});
            })
            updateListItem({listItem: sublist});
        }
    }
}

function getSublist({list, position, i}) {
    if (i + 2 <= position.length) {
        let ptemp = null;
        let p2temp = null;
        // add list item if it not exists
        if (list.children[position[i]] == null) {
            list.children[position[i]] = {
                type: "LI",
                children: [{
                    type: "LBody",
                    children: [{
                        type: "L",
                        children: []
                    }],
                    uuid: uuidv4()
                }],
                uuid: uuidv4()
            };
        }
        // add list body if not exists
        if (list.children[position[i]].children.every(liE => liE.type !== "LBody")) {
            list.children[position[i]].children.push({
                type: "LBody",
                children: [{
                    type: "L",
                    children: []
                }],
                uuid: uuidv4()
            });
        }
        // get sublist
        list.children[position[i]].children.every((liE, liEi) => {
            if (liE.type === "LBody") {
                // add list if not exists
                if (liE.children.every(lbe => lbe.type !== "L")) {
                    liE.children.push({
                        type: "L",
                        children: [],
                        uuid: uuidv4()
                    });
                }
                liE.children.every((lbE, lbEi) => {
                    if (lbE.type === "L") {
                        ptemp = liEi;
                        p2temp = lbEi;
                        return false;
                    }
                    return true;
                })
                return false;
            }
            return true;
        })
        return getSublist({
            list: list.children[position[i]].children[ptemp].children[p2temp],
            position: position,
            i: i + 1
        });
    }
    return list;
}

function listAsHtml({list, setRender, setHighlightListItem}) {
    if (list == null) return

    // go through the list
    const flatList = getFlatList(list)
    let result = []
    let prevPosition = []
    for (let listItemI = 0; listItemI < flatList.length; listItemI++) {
        const listItem = flatList[listItemI];

        // skip if not first connected element
        if (listItem.connectedElements.length > 0 && listItem.connectedElements[0] !== listItem.uuid) {
            continue;
        }

        // get position of item
        let position = getListItemPosition(list, listItem);
        // check for connected elements
        prevPosition.forEach((p, pi) => {
            if (pi < position.length - 1 && p > position[pi]) {
                position[pi] = p;
            }
            if (pi === position.length - 1 && p >= position[pi]) {
                position[pi] = p + 1;
            }
        });

        let positionOptions = [];
        prevPosition.forEach((p, pi) => {
            positionOptions.push(prevPosition.slice(0, pi).concat([p + 1]));
            if (pi + 2 > prevPosition.length) {
                positionOptions.push(prevPosition.slice(0, pi).concat([p, 0]));
            }
        })
        if (positionOptions.length === 0) positionOptions.push(position);
        positionOptions.sort();

        let lblText = listItem.lblChildren.map(e => e.text).join(" ");
        let listItemText = listItem.lBodyChildren.map(e => e.text).join(" ");
        let uuids = [listItem.uuid];

        if (listItem.connectedElements.length > 1) {
            let connectedListItems = [];
            listItem.connectedElements.forEach(connectedUuid => {
                if (connectedUuid === listItem.uuid) {
                    return;
                }
                const connectedItem = getListItemUuid(list, connectedUuid);
                if (connectedItem != null) {
                    connectedListItems.push(connectedItem);
                }
            })
            connectedListItems.forEach(li => {
                lblText += li.lblChildren.map(e => e.text).join(" ");
                listItemText += li.lBodyChildren.map(e => e.text).join(" ");
                uuids.push(li.uuid);
            });
        }

        // return the list item
        result.push(<li key={listItem.uuid}>
            <div className={"whiteBox flex-row align-items-center overflow-visible"} onMouseEnter={() => {
                setHighlightListItem(uuids);
            }} onMouseLeave={() => {
                setHighlightListItem(null);
            }} style={{marginLeft: (position.length - 1) + "em"}}>
                <DropdownButton title={position.map(o => o + 1).join(".")} variant="light" key="listItemDropdown">
                    {positionOptions.map((o, i) => {
                        return <Dropdown.Item key={i} onClick={() => {
                            changeListItemPosition(list, listItem, o);
                            setRender(prevState => prevState + 1);
                        }} active={position.map(o => o + 1).join(".") === o.map(o => o + 1).join(".")}>
                            {o.map(o => o + 1).join(".")}
                        </Dropdown.Item>
                    })}
                </DropdownButton>
                <p className="listItemText">{lblText + " " + listItemText}</p>
            </div>
        </li>)

        prevPosition = position;
    }
    return result;
}

function getListItemUuid(list, uuid) {
    for (let listItem of list) {
        if (listItem.uuid === uuid) {
            return listItem;
        }
        if (listItem.sublist.length > 0) {
            const subListSearch = getListItemUuid(listItem.sublist, uuid);
            if (subListSearch != null) {
                return subListSearch;
            }
        }
    }
    return null;
}

function mergeListItems(listItem1, listItem2) {
    listItem1.lblChildren = listItem1.lblChildren.concat(listItem2.lblChildren);
    listItem1.lBodyChildren = listItem1.lBodyChildren.concat(listItem2.lBodyChildren);
    listItem1.sublist = listItem1.sublist.concat(listItem2.sublist);
    if (listItem2.connectedElements.length > 0) {
        const oldUuid = listItem1.uuid;
        listItem1.uuid = listItem2.uuid;
        listItem2.uuid = oldUuid;
        listItem1.connectedElements = listItem2.connectedElements;
    }
}

function deleteListLine({list, selectedLine, baseListUuid}) {
    let flatList = getFlatList(list);
    let aboveElement = null;
    let belowElement = null;
    let listPosition = 0;
    let startsublist = false;
    for (let listItemI = 0; listItemI < flatList.length; listItemI++) {
        if (baseListUuid === flatList[listItemI].baseListUuid) {
            startsublist = true;
        }
        if (startsublist) {
            if (listPosition === selectedLine) {
                belowElement = flatList[listItemI];
                break;
            }
            listPosition++;
        }
        aboveElement = flatList[listItemI];
    }
    if (aboveElement === null || belowElement === null) {
        throw new Error("could not find above or below element");
    }
    // if not same base list
    if (aboveElement.baseListUuid !== belowElement.baseListUuid) {
        let positionAbove = getListItemPosition(list, aboveElement);
        positionAbove[positionAbove.length - 1]++;
        changeListItemPosition(list, belowElement, positionAbove);
        aboveElement.connectedElements = belowElement.connectedElements = [aboveElement.uuid, belowElement.uuid];
    }
    else {
        mergeListItems(aboveElement, belowElement);
        removeListItem(list, belowElement);
    }
}

function removeListItem(list, listItem) {
    let removeListItems = [];
    for (let listItemI = 0; listItemI < list.length; listItemI++) {
        if (list[listItemI].sublist.length > 0) {
            removeListItem(list[listItemI].sublist, listItem);
        }
        // if list item or list item is empty
        if (list[listItemI].uuid === listItem.uuid || (list[listItemI].lblChildren.length === 0 && list[listItemI].lBodyChildren.length === 0 && list[listItemI].sublist.length === 0)) {
            removeListItems.push(listItemI);
        }
    }
    for (let removeI = 0; removeI < removeListItems.length; removeI++) {
        list.splice(removeListItems[removeI] - removeI, 1);
    }
}

function getListItemPosition(list, listItem) {
    let correctionFactor = 0;
    for (let listItemI = 0; listItemI < list.length; listItemI++) {
        // if connected element
        if (list[listItemI].connectedElements.length > 1 && list[listItemI].connectedElements[0] !== list[listItemI].uuid) {
            correctionFactor++;
        }
        // check if list item
        if (list[listItemI].uuid === listItem.uuid) {
            // if connect first item
            return [listItemI - correctionFactor];
        }
        // check if sublist
        if (list[listItemI].sublist.length > 0) {
            const result = getListItemPosition(list[listItemI].sublist, listItem);
            if (result !== null) {
                return [listItemI - correctionFactor].concat(result);
            }
        }
    }
    return null;
}

function getCorrectionFactor(list, position) {
    let correctionFactor = 0;
    for (let listItemI = 0; listItemI < list.length; listItemI++) {
        // if connected element
        if (list[listItemI].connectedElements.length > 1 && list[listItemI].connectedElements[0] !== list[listItemI].uuid) {
            correctionFactor++;
        }
        // found
        if (listItemI - correctionFactor === position) {
            break;
        }
    }
    return correctionFactor;
}

function changeListItemPosition(list, listItem, newPosition) {
    removeListItem(list, listItem);
    let currentList = list;
    let connectedItem = null;
    for (let positionI = 0; positionI < newPosition.length; positionI++) {
        // get correction factor of list
        let correctionFactor = getCorrectionFactor(currentList, newPosition[positionI]);
        // if last position
        if (positionI === newPosition.length - 1) {
            currentList.splice(newPosition[newPosition.length - 1] + correctionFactor, 0, listItem);
            break;
        }
        // get the sublist item
        let sublistItem = currentList[newPosition[positionI] + correctionFactor];
        if (sublistItem == null) {
            sublistItem = {
                lblChildren: [],
                lBodyChildren: [],
                sublist: [],
                uuid: uuidv4(),
                connectedElements: [],
                baseListUuid: listItem.baseListUuid,
            };
            if (connectedItem !== null) {
                const connectedElements = [connectedItem.uuid, sublistItem.uuid];
                connectedItem.connectedElements = sublistItem.connectedElements = connectedElements;
            }
            currentList.push(sublistItem);
        }
        connectedItem = null;
        if (sublistItem.baseListUuid === listItem.baseListUuid) {
            currentList = sublistItem.sublist;
        }
        else {
            correctionFactor++;
            currentList.splice(newPosition[positionI] + correctionFactor, 0, {
                lblChildren: [],
                lBodyChildren: [],
                sublist: [],
                uuid: uuidv4(),
                connectedElements: [],
                baseListUuid: listItem.baseListUuid,
            });
            const connectedElements = [sublistItem.uuid, list[newPosition[positionI] + correctionFactor].uuid];
            currentList[newPosition[positionI] + correctionFactor].connectedElements = sublistItem.connectedElements = connectedElements;
            connectedItem = sublistItem.sublist[sublistItem.sublist.length - 1];
            currentList = list[newPosition[positionI] + correctionFactor].sublist;
        }
    }
}


export {getSublist, updateList, checkList, updateListItem, addListItem, getListItemPosition,
    changeListItemPosition, deleteListLine, listAsHtml, getAllMCRefs, processLists, getListRectangle,
    getBaseListUuids, getListItemRectangle, getFlatList};
