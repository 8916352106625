import {changeTagType} from "../Tools";

function checkHeadingLevels({headings, modifications}) {
    let lastLevel = 0;
    headings.forEach((heading) => {
        if (heading.type === "H") {
            changeTagType({
                structTree: headings,
                selectedStructElem: heading,
                modifications: modifications,
                newType: "H1"});
            lastLevel = 1;
        }
        else if (heading.type === "Title") {
            lastLevel = 0;
        }
        else {
            const level = Number(heading.type.substring(1));
            if (level - 1 > lastLevel) {
                changeTagType({
                    structTree: headings,
                    selectedStructElem: heading,
                    modifications: modifications,
                    newType: "H" + String(lastLevel + 1)});
                lastLevel += 1;
            }
            else {
                lastLevel = level;
            }
        }
    });
    return headings
}

function updateHeadings({heading, level, headings, modifications}) {
    if (heading.type === "H" || level !== heading.type.split("H")[1]) {
        changeTagType({
            structTree: headings,
            selectedStructElem: heading,
            modifications: modifications,
            newType: "H"+ level});
    }
}

function detectHeadingLevels({headings, modifications}) {
    let headingStructure = [];
    let lastLevel = -1;
    headings.forEach(heading => {
        // get font of heading
        let font = [];
        heading.children.forEach(MCRef => {
            if (MCRef.font !== "") {
                font.push(MCRef.font);
            }
        })
        font = font.reduce((a,b,i,arr)=>(arr.filter(v=>v===a).length>=arr.filter(v=>v===b).length?a:b), null);
        // check if heading is in heading list
        const foundHeadingLevel = !headingStructure.every((h, hi) => {
            if (h.includes(font)) {
                lastLevel = hi;
                updateHeadings({heading: heading, level: hi + 1, headings: headings, modifications: modifications});
                return false;
            }
            return true;
        });
        if (!foundHeadingLevel) {
            let tempHeadingLevel = headingStructure[lastLevel + 1];
            if (tempHeadingLevel == null) headingStructure[lastLevel + 1] = [font];
            else headingStructure[lastLevel + 1].push(font);
            lastLevel += 1;
            updateHeadings({heading: heading, level: lastLevel + 1, headings: headings, modifications: modifications});
        }
    })
}

function getLastHeadingLevel({headings, selectedHeading}) {
    let level = 0;
    headings.every(heading => {
        if (heading === selectedHeading) {
            return false;
        }
        if (heading.type === "Title") {
            level = 0;
            return true;
        }
        level = Number(heading.type.substring(1));
        return true;
    })
    return level;
}

export {checkHeadingLevels, detectHeadingLevels, getLastHeadingLevel};
