import {imageScaling, regionConfig} from "./Config";
import {updateTagType, newTag} from "./StructTreeActions";
import Button from "react-bootstrap/Button";
import {FaFastBackward, FaFastForward, FaStepBackward, FaStepForward} from "react-icons/fa";
import Form from 'react-bootstrap/Form';

function isMarked(operator, subStructTree) {
    if (subStructTree.type !== "MCRef") {
        return subStructTree.children.some( subsubStructTree => {
            return isMarked(operator, subsubStructTree);
        })
    }
    return operator.uuid === subStructTree.uuid;
}

function getStructElemByPoint(structTree, point, imageSize, showArtifacts) {
    let foundStructElem = null;
    structTree.forEach(structElem => {
        if (structElem.type === "MCRef") return;
        if (structElem.type === "Artifact" && !showArtifacts) return;
        const rect = transformRectangle({rect: structElem.rectangle, imageSize: imageSize});
        if (rect.x - regionConfig.clickOffset <= point.x && point.x <=  rect.x + rect.w + regionConfig.clickOffset && rect.y - regionConfig.clickOffset <= point.y && point.y <= rect.y + rect.h + regionConfig.clickOffset) {
            if (!regionConfig.doNotShow.includes(structElem.type) && (foundStructElem == null ||
                (structElem.rectangle.urx - structElem.rectangle.llx) *
                (structElem.rectangle.ury - structElem.rectangle.lly) <
                (foundStructElem.rectangle.urx - foundStructElem.rectangle.llx) *
                (foundStructElem.rectangle.ury - foundStructElem.rectangle.lly))) {
                foundStructElem = structElem;
            }
            const foundChildStructElem = getStructElemByPoint(structElem.children, point, imageSize, showArtifacts);
            if (foundChildStructElem !== null) {
                foundStructElem = foundChildStructElem;
            }
        }
    });
    return foundStructElem;
}

function changeTagType({structTree, selectedStructElem, modifications, newType}) {
    structTree.forEach((structElem, index) => {
        if (structElem.type === "MCRef") {
            return;
        }
        if (structElem === selectedStructElem) {
            if (structElem.uuid !== null) {
                // if a connected element
                if (selectedStructElem.connectedElements.length > 1) {
                    selectedStructElem.connectedElements.forEach(connectedElement => {
                        modifications.push(updateTagType({uuid: connectedElement.uuid, newType: newType}));
                        structTree.forEach(elem => {
                            if (elem.uuid === connectedElement.uuid) {
                                elem.type = newType
                            }
                        })
                    });
                }
                else {
                    modifications.push(updateTagType({uuid: selectedStructElem.uuid, newType: newType}));
                    structTree[index].type = newType;
                }
            }
            else {
                console.log("Struct Elem should have uuid")
            }
        }
        else if (structElem.children !== null) {
            changeTagType({structTree: structElem.children,
                selectedStructElem: selectedStructElem, modifications: modifications, newType: newType})
        }
    })
}

function getNotMarked({operators, structTree}) {
    if (operators != null && structTree != null) {
        return operators.filter(operator => {
            return !structTree.some(subStructTree => {return isMarked(operator, subStructTree)});
        });
    }
    return null;
}

function markEmptyOperatorsAsArtifacts({operators, structTree, modifications}) {
    if (operators != null && structTree != null) {
        let elem = {type: "Artifact", rectangle: {llx: 99999, lly: 99999, urx: -1, ury: -1}, text: ""};
        const children = operators.filter(operator => ((operator.rectangle.urx - operator.rectangle.llx) * (operator.rectangle.ury - operator.rectangle.lly)) === 0);
        if (children.length === 0) return
        elem.children = children;
        // update text and bounding box of new element
        elem.children.forEach(o => {
            elem.text += o.text;
            if (elem.rectangle.llx > o.rectangle.llx) elem.rectangle.llx = o.rectangle.llx;
            if (elem.rectangle.urx < o.rectangle.urx) elem.rectangle.urx = o.rectangle.urx;
            if (elem.rectangle.lly > o.rectangle.lly) elem.rectangle.lly = o.rectangle.lly;
            if (elem.rectangle.ury < o.rectangle.ury) elem.rectangle.ury = o.rectangle.ury;
        });
        newTag({elem: elem, modifications: modifications, structTree: structTree});
    }
}


function transformRectangle({rect, imageSize}) {
    let newRect = {x: (rect.llx)*imageScaling, y: (rect.lly)*imageScaling, w: (rect.urx - rect.llx)*imageScaling, h: (rect.ury - rect.lly)*imageScaling};
    if (newRect.w < 10) {
        const diff = 10 - newRect.w;
        newRect.w = 10;
        newRect.x -= (diff / 2);
    }
    if (newRect.h < 10) {
        const diff = 10 - newRect.h;
        newRect.h = 10;
        newRect.y -= (diff / 2);
    }
    return newRect;
}

function getMidPoint({rect}) {
    return {x: 0.5*(rect.llx + rect.urx)*imageScaling, y: 0.5*(rect.ury + rect.lly)*imageScaling};
}

function getBoundingBoxCell({elements}) {
    let llx = 9999;
    let lly = 9999;
    let urx = 0;
    let ury = 0;
    let page = elements[0].rectangle.page;
    elements.forEach(e => {
        if (e.rectangle.llx < llx) {
            llx = e.rectangle.llx;
        }
        if (e.rectangle.lly < lly) {
            lly = e.rectangle.lly;
        }
        if (e.rectangle.urx > urx) {
            urx = e.rectangle.urx;
        }
        if (e.rectangle.ury > ury) {
            ury = e.rectangle.ury;
        }
    });
    return {llx: llx, lly: lly, urx: urx, ury: ury, page: page}
}

function getMousePos({e, canvasRef}) {
    const rect = canvasRef.getBoundingClientRect();
    // e.clientX * xScale.current - canvasX.current
    return {'x': (e.clientX  - rect.left) * canvasRef.width / rect.width,
        'y': (e.clientY - rect.top) * canvasRef.height / rect.height};
}

function getChangeBoxPoint({curX, curY, rect, threshold}) {
    // point 1
    if ((curX < rect.x + threshold && curX > rect.x - threshold) &&
        (curY < rect.y + threshold && curY > rect.y - threshold)) {
        return 1;
    }
    //point 2
    else if ((curX < rect.x + rect.w/2 + threshold && curX > rect.x + rect.w/2 - threshold) &&
        (curY < rect.y + threshold && curY > rect.y - threshold)) {
        return 2;
    }
    //point 3
    else if ((curX < rect.x + rect.w + threshold && curX > rect.x + rect.w - threshold) &&
        (curY < rect.y + threshold && curY > rect.y - threshold)) {
        return 3;
    }
    //point 4
    else if ((curX < rect.x + rect.w + threshold && curX > rect.x + rect.w - threshold) &&
        (curY < rect.y + rect.h/2 + threshold && curY > rect.y + rect.h/2 - threshold)) {
        return 4;
    }
    //point 5
    else if ((curX < rect.x + rect.w + threshold && curX > rect.x + rect.w - threshold) &&
        (curY < rect.y + rect.h + threshold && curY > rect.y + rect.h - threshold)) {
        return  5;
    }
    //point 6
    else if ((curX < rect.x + rect.w/2 + threshold && curX > rect.x + rect.w/2 - threshold) &&
        (curY < rect.y + rect.h + threshold && curY > rect.y + rect.h - threshold)) {
        return  6;
    }
    //point 7
    else if ((curX < rect.x + threshold && curX > rect.x - threshold) &&
        (curY < rect.y + rect.h + threshold && curY > rect.y + rect.h - threshold)) {
        return  7;
    }
    //point 8
    else if ((curX < rect.x + threshold && curX > rect.x - threshold) &&
        (curY < rect.y + rect.h/2 + threshold && curY > rect.y + rect.h/2 - threshold)) {
        return  8;
    }
    else {
        return  false;
    }
}

function mode(arr) {
    const mode = {};
    let max = null, count = 0;

    for(let i = 0; i < arr.length; i++) {
        const item = arr[i];

        if(mode[item]) {
            mode[item]++;
        } else {
            mode[item] = 1;
        }

        if(count < mode[item]) {
            max = item;
            count = mode[item];
        }
    }

    return max;
};

function navigation({callback, length, i, title, viewed, parts=false}) {
    let id = "navigationInput";
    if (parts) {
        id += "Parts";
    }

    function changeElement(e) {
        const number = Number(e.target.value);
        if (number <= length && number > 0) {
            callback(number - 1);
        }
        else {
            e.target.value = i + 1;
        }
    }

    if (document.getElementById(id) != null) {
        document.getElementById(id).value = i + 1;
    }
    viewed[i] = true;
    return <div className="whiteBox navigationBar">
        <h3>{title}</h3>
        <Button onClick={() => callback(0)} disabled={i <= 0 || i == null} aria-disabled={i <= 0 || i == null} aria-label={"Go to " + title + " 0"} variant="light"><FaFastBackward/></Button>
        <Button onClick={() => callback(i - 1)} disabled={i <= 0 || i == null} aria-disabled={i <= 0 || i == null} aria-label={"Go to " + title + " " + i} variant="light"><FaStepBackward/></Button>
        <Form>
            <Form.Group>
                <Form.Control onChange={(text) => changeElement(text)} defaultValue={i != null ? i + 1 : 0} id={id} disabled={i == null} aria-disabled={i == null} aria-label={"current " + title}/>
            </Form.Group>
        </Form>
        <p>
            / {length}
        </p>
        <Button onClick={() => callback(i + 1)} disabled={i === length - 1 || i == null} aria-disabled={i === length - 1 || i == null} aria-label={"Go to " + title + " " + i + 2} variant="light"><FaStepForward/></Button>
        <Button onClick={() => callback(length - 1)} disabled={i === length - 1 || i == null} aria-disabled={i === length - 1 || i == null} aria-label={"Go to " + title + " " + length} variant="light"><FaFastForward/></Button>
        {viewed.filter(x => !x).length > 0 ? <p className="notCheckedHint">({viewed.filter(x => !x).length} {title} not checked)</p> : null}
    </div>
}

function imageCropper({image, rectangle, border, resize}) {
    const canvas = document.createElement('canvas');
    const x = Math.max(0, rectangle.x - border);
    const y = Math.max(0, rectangle.y - border);
    const w = Math.min(image.naturalWidth - rectangle.x, rectangle.w + 2 * border);
    const h = Math.min(image.naturalHeight - rectangle.y, rectangle.h + 2 * border);
    canvas.width = w * resize;
    canvas.height = h * resize;

    const ctx = canvas.getContext('2d');

    // Draw the cropped image on the canvas
    ctx.drawImage(
        image,
        x, // x-coordinate for the top-left corner of the source rectangle
        y, // y-coordinate for the top-left corner of the source rectangle
        w, // Width of the source rectangle
        h, // Height of the source rectangle
        0,         // x-coordinate for the top-left corner of the destination rectangle
        0,         // y-coordinate for the top-left corner of the destination rectangle
        w * resize, // Width of the destination rectangle
        h * resize  // Height of the destination rectangle
    );


    return canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");  // here is the most important part because if you dont replace you will get a DOM 18 exception.
};

function processConnectedElements(elements) {
    // process connected elements
    return elements.map(element => {
        if (element.connectedElements != null && element.connectedElements.length > 0) {
            // replace the connected elements with the original elements
            element.connectedElements = element.connectedElements.map(elementConnected => elements.filter(e => e.uuid === elementConnected.uuid)[0]);
        }
        return element;
    }).filter(element => !(element.connectedElements != null && element.connectedElements.length > 0 && element.connectedElements[0].uuid !== element.uuid));
}

function changeElementI({i, elementI, pageNum, elements, setElementI, setElementPartI, callback}) {
    if (i !== elementI) {
        if (callback != null) {
            callback(i);
        }
        setElementPartI(0);
        setElementI(i);
        return elements[i].rectangle.page;
    }
    return pageNum;
}

function changeElementPartI({i, elementI, elementPartI, pageNum, elements, setElementPartI, callback}) {
    if (i !== elementPartI) {
        if (callback != null) {
            callback(i);
        }
        setElementPartI(i);
        return elements[elementI].connectedElements[i].rectangle.page;
    }
    return pageNum;
}

function getElement(elements, elementI, elementPartI) {
    if (elements == null || elements.length === 0) {
        return null;
    }
    let element = elements[elementI];
    if (element.connectedElements != null && element.connectedElements.length > 1) {
        element = element.connectedElements[elementPartI];
    }
    return element;
}

function pointIsInside(point, rectangle, offset=0) {
    return point.x > rectangle.x - offset && point.x < rectangle.x + rectangle.w + offset && point.y > rectangle.y + point.y < rectangle.y + rectangle.h + offset;
}

export {isMarked, getStructElemByPoint, getNotMarked, transformRectangle, changeTagType, getMidPoint, navigation,
    getBoundingBoxCell, getChangeBoxPoint, getMousePos, mode, imageCropper, markEmptyOperatorsAsArtifacts,
processConnectedElements, changeElementI, changeElementPartI, getElement, pointIsInside};

