import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import { QueryClient, QueryClientProvider} from "@tanstack/react-query";
import { ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {BrowserRouter} from "react-router-dom";

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById("root")).render(
    //<React.StrictMode>
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools />
        </QueryClientProvider>
    </BrowserRouter>
    //</React.StrictMode>
);
