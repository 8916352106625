import React from "react";
import {Link} from "react-router-dom";

const Step2 = () => {
    return <div className="instructionsText">
        <Link to="/help#whatIsAReadingOrder" target={"_blank"}>More information about reading order</Link>.
        <ul>
            <li><strong>Reading Order completely wrong:</strong> Select <em>Detect Reading Order</em> to automatically detect the Reading Order or select <em>Draw Reading Order</em> and draw the Reading Order on the Page View. Red identifies not selected boxes and green identifies selected ones.</li>
            <li><strong>Minor Reading Order corrections:</strong> Change the Reading Order via the list in the blue box by moving the items up and down.</li>
        </ul>
    </div>
}

export default Step2;
