import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import Step0 from "./Instructions/Step0";
import Step1 from "./Instructions/Step1";
import Step2 from "./Instructions/Step2";
import Step3 from "./Instructions/Step3";
import Step5 from "./Instructions/Step5";
import Step4 from "./Instructions/Step4";
import Step6 from "./Instructions/Step6";
import Step7 from "./Instructions/Step7";
import Step8 from "./Instructions/Step8";
import Step7MathEditor from "./Instructions/Step7MathEditor";
import {Accordion} from "react-bootstrap";


const Instructions = ({title, step}) => {

    return <div className="whiteBox instructions">
            <h2>
                {title}
            </h2>
        <Accordion defaultActiveKey="">
            <Accordion.Item eventKey="0">
                <Accordion.Header as="h3">How to do that?</Accordion.Header>
                <Accordion.Body>
                    {{
                        0: <Step0 />,
                        1: <Step1 />,
                        2: <Step2 />,
                        3: <Step3 />,
                        4: <Step4 />,
                        5: <Step5 />,
                        6: <Step6 />,
                        7: <Step7 />,
                        8: <Step8 />,
                        "MathEditor": <Step7MathEditor />,
                    }[step]}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
}
export default Instructions;
