import React, {useEffect, useRef, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getPageImagePdfJs, getTagType, predictFormula, updateTags} from "./APIcalls";
import {updateAltText} from "./StructTreeActions";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import MathEditor from "./MathEditor";
import {drawFigures, loadingCanvas} from "./Drawing";
import {changeElementI, imageCropper, navigation} from "./Tools";
import {imageScaling} from "./Config";
import {errorDrawing, WarningNotComplete} from "./utils/ErrorMessages";
import Instructions from "./utils/Instructions";
import {MdOutlineAutoFixHigh} from "react-icons/md";
import {resizingX, startResizeX} from "./utils/UtilsResize";
import PageView from "./PageView";
import StepNavigationButtons from "./StepNavigationButtons";
import MathLive from 'mathlive';
import {BeatLoader} from "react-spinners";
import {RiTranslate} from "react-icons/ri";
import {ButtonGroup} from "react-bootstrap";
import VirtualKeyboard from "./utils/VirtualKeyboard";

const Formulae = ({pdf, pdfInfo, stepSelected, setStep, menuSize, setMenuSize, showPageMenu, setShowPageMenu, setStepSelected}) => {

    const formulas = useRef([]);
    const [formulaI, setFormulaI] = useState(-1);
    const [formulaPartI, setFormulaPartI] = useState(0);
    const pageNum = useRef(1);
    const [imageReady, setImageReady] = useState(true);
    const modifications = useRef([]);
    const [useMathEditor, setUseMathEditor] = useState(false);
    const canvasRef = useRef(null);
    const ctx = useRef(null);
    const [renderI, setRenderI] = useState(0);

    const queryClient = useQueryClient();
    const viewed = useRef([true]);
    const [showWarning, setShowWarning] = useState(false);
    const [zoomFactor, setZoomFactor] = useState(1);

    // resize start values
    const startResizeValueMenu = useRef(null);

    // math editor values
    const SRE = useRef(null);
    const [mathEditorOption, setMathEditorOption] = useState("mathEditor");

    // changing step
    useEffect(() => {
        if (stepSelected !== -1) {
            const checkedAll = viewed.current.every(t => t || stepSelected < 7);
            updateStep(() => {
                queryClient.invalidateQueries(["Formulas", pdfInfo.fileid]).then(() => {
                    if (checkedAll) setStep(stepSelected);
                    else setShowWarning(true);
                });
            });
        }
    }, [stepSelected]);

    // init canvas
    useEffect(() => {
        SRE.current = window.MathJax._.a11y.sre.Sre;
        if (canvasRef.current) {
            ctx.current = canvasRef.current.getContext('2d');
        }
        formulasQuery.refetch();
        viewed.current[formulaI] = true;
    }, []);

    // update canvas after math editor
    useEffect(() => {
        if (canvasRef.current) {
            ctx.current = canvasRef.current.getContext('2d');
            drawing();
        }
    }, [canvasRef.current]);


    // get the formulas
    const formulasQuery = useQuery({
        queryKey: ["Formulas", pdfInfo.fileid],
        queryFn: () => getTagType({pdfInfo: pdfInfo, tagType: "Formula", pdf: pdf}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: data => {
            formulas.current = data;
            if (formulas.current.length !== 0) {
                pageNum.current = formulas.current[0].rectangle.page;
                if (viewed.current.length === 1) {
                    viewed.current = Array.from({length: formulas.current.length}, _ => false);
                }
                viewed.current[0] = true;
                setFormulaI(0);
            }
        }
    });

    // get the image
    const pageImage = useQuery({
        queryKey: ["image", pdfInfo.fileid, pageNum.current],
        queryFn: () => getPageImagePdfJs({pdf: pdf, pageNum: pageNum.current, setImageReady: setImageReady}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null && pageNum.current > -1,
        placeholderData: null,  // placeholder image
    });

    // update formulas
    const formulasMutation = useMutation({
        mutationFn: ({pdfInfo, modifications}) => updateTags({pdfInfo: pdfInfo, modifications: modifications})
    })

    // predict alt text
    const predictMERMutation = useMutation({
        mutationFn: ({pdfInfo, formulaId}) => predictFormula({pdfInfo: pdfInfo, formulaId: formulaId}),
        onSuccess: (data) => {
            formulas.current[formulaI].latex = data.data;
            document.getElementById(mathEditorOption).value = data.data;
            setRenderI(prevState => prevState + 1);
        },
        onError: error => {
            setRenderI(prevState => prevState + 1);
        }
    })

    function changeFormulaCallback(i) {
        if (i !== formulaI) {
            document.getElementById(mathEditorOption).value = formulas.current[formulaI].latex;
            document.getElementById("formulaAltText").value = formulas.current[formulaI].altText;
            updateStep();
            drawing();
        }
    }

    /**
     * Helper function for updating the step
     */
    function updateStep(onSuccessFunction) {
        if (formulas.current[formulaI] != null) {
            modifications.current.push(updateAltText({
                uuid: formulas.current[formulaI].uuid,
                altText: formulas.current[formulaI].altText
            }));
        }
        return formulasMutation.mutate({pdfInfo: pdfInfo, modifications: modifications.current}, {
            onSuccess: () => {
                if (onSuccessFunction) {
                    return onSuccessFunction();
                }
            }
        });
    }

    function convertLatexToMathSpeak(latex) {
        SRE.current.setupEngine(
            {locale: "en", domain: "mathspeak", modality: "speech",
                style: "default", markup: "none", pprint: true
            })
            .then(() => {
                formulas.current[formulaI].altText = SRE.current.toSpeech(window.MathJax.tex2mml(latex));
            })
            .then(() => {
                document.getElementById("formulaAltText").value = formulas.current[formulaI].altText;
                setRenderI(prevState => prevState + 1);
            });
    }

    /**
     * helper function for drawing
     */
    function drawing(){
        if (!formulasQuery.isLoading && imageReady && ctx.current != null && pageImage.data != null) {
            try {
                canvasRef.current.height = pageImage.data.naturalHeight;
                canvasRef.current.width = pageImage.data.naturalWidth;
                drawFigures({ctx: ctx.current, figure: formulas.current[formulaI], image: pageImage.data});
            }
            catch (e) {
                errorDrawing({errorMessage: e});
            }
        }
        else {
            if (imageReady && ctx.current != null && pageImage.data != null) {
                drawFigures({ctx: ctx.current, figure: null, image: pageImage.data})
            }
            else if (!formulasQuery.isLoading && ctx.current != null) {
                loadingCanvas({ctx: ctx.current, canvasRef: canvasRef.current});
            }
        }
    }

    drawing();

    function showFormula() {
        drawing();
        if (imageReady && pageImage.data != null && formulas.current.length > 0) {
            return <div className="blueBox overflow-auto">
                <h3>Formula Alternative Text</h3>
                <div className="figureImgDiv flex-shrink-0">
                    <img src={imageCropper({
                        image: pageImage.data,
                        border: 5,
                        rectangle: {
                            x: formulas.current[formulaI].rectangle.llx * imageScaling,
                            y: formulas.current[formulaI].rectangle.lly * imageScaling,
                            w: formulas.current[formulaI].rectangle.urx * imageScaling - formulas.current[formulaI].rectangle.llx * imageScaling,
                            h: formulas.current[formulaI].rectangle.ury * imageScaling - formulas.current[formulaI].rectangle.lly * imageScaling
                        },
                        resize: 1
                    })}/>
                </div>
                <div className="mathEditorButtons">
                    <ButtonGroup>
                        <Button active={mathEditorOption === "mathEditor"} onClick={() => {
                            formulas.current[formulaI].latex = document.getElementById(mathEditorOption).value;
                            setMathEditorOption("mathEditor");
                        }} variant="light" disabled={predictMERMutation.isLoading}>Math Editor</Button>
                        <Button active={mathEditorOption === "latexInput"} onClick={() => {
                            formulas.current[formulaI].latex = document.getElementById(mathEditorOption).value;
                            setMathEditorOption("latexInput");
                        }} variant="light" disabled={predictMERMutation.isLoading}>LaTeX</Button>
                    </ButtonGroup>
                </div>
                <div className="mathEditorInputArea">
                    {{
                        "mathEditor": <math-field
                            id="mathEditor"
                            value={formulas.current[formulaI].latex}
                        />,
                        "latexInput": <textarea
                            rows={5}
                            className="latexInput"
                            id="latexInput"
                            defaultValue={formulas.current[formulaI].latex}
                            disabled={predictMERMutation.isLoading}
                        />,
                    }[mathEditorOption]}
                    {predictMERMutation.isLoading ? <BeatLoader className="loadingSymbol" /> : null}
                </div>
                {mathEditorOption === "mathEditor" ? <VirtualKeyboard mathInputRef={() => document.getElementById(mathEditorOption)}/> : null}
                <div className="buttonRow">
                    <Button
                        onClick={() => predictMERMutation.mutate({pdfInfo: pdfInfo, formulaId: formulas.current[formulaI].uuid})}
                        variant="light" className="blueBoxButton" disabled={predictMERMutation.isLoading}>
                        <MdOutlineAutoFixHigh size="1.5em"/><br/>
                        Recognize Formula
                    </Button>
                    <Button onClick={() => {
                        formulas.current[formulaI].latex = document.getElementById(mathEditorOption).value;
                        try {
                            convertLatexToMathSpeak(formulas.current[formulaI].latex);
                        } catch (e) {
                            console.log(e);
                        }
                    }} variant="light" className="blueBoxButton" disabled={predictMERMutation.isLoading}>
                        <RiTranslate size="1.5em"/><br/>
                        Convert
                    </Button>
                </div>
                <Form className="alternativeTextInput">
                    <Form.Group>
                        <Form.Label className="altTextTitle" as="h4">Alternative Text (Modify Carefully)</Form.Label>
                        <Form.Control id='formulaAltText' type="alternative text" as="textarea" rows={5}
                                      defaultValue={formulas.current[formulaI].altText}
                                      onChange={e => formulas.current[formulaI].altText = e.target.value}
                                      disabled={predictMERMutation.isLoading} className="alternativeTextInputArea"
                        />
                    </Form.Group>
                </Form>
            </div>
        }
        return <div className="blueBox">
            <h3>No formulas found in the document</h3>
        </div>;
    }

    if (useMathEditor) {
        return <MathEditor formula={formulas.current[formulaI]} setUseMathEditor={setUseMathEditor} img={pageImage.data}
                           pdfInfo={pdfInfo}/>
    }

    return <div className="workspaceArea"
                onMouseUp={() => startResizeValueMenu.current = null}
                onMouseMove={(e) => resizingX(e, startResizeValueMenu.current, setMenuSize)}
                style={startResizeValueMenu.current != null ? {userSelect: 'none'}: null}>
            <WarningNotComplete
                showWarning={showWarning} setShowWarning={setShowWarning}
                titleMessage={"Have you checked all formulae?"}
                bodyMessage={<>It seems that you have not checked the following
                    formulae: {viewed.current.map((e, ei) => e ? null : ei + 1).filter(e => e != null).join(", ")}
                    <br/> Do you want to check them before you continue?</>}
                currentTask={7}
                setTask={setStep}
                taskSelected={stepSelected}/>
        <div id="menu" className="menu" style={{width: menuSize + "px"}}>
            <div className="resizeHandleX" onMouseDown={(e) => startResizeValueMenu.current = startResizeX(e)}></div>
            <Instructions
                title="Step 7: Edit Alternative Text of Mathematical Formulas"
                step={7}
            />
            {navigation({
                callback: (i) => pageNum.current = changeElementI({
                    i: i,
                    elementI: formulaI,
                    pageNum: pageNum.current,
                    elements: formulas.current,
                    setElementI: setFormulaI,
                    setElementPartI: setFormulaPartI,
                    callback: changeFormulaCallback
                }),
                length: formulas.current.length,
                i: formulaI,
                title: "Formulas",
                viewed: viewed.current
            })}
            {showFormula()}
            <StepNavigationButtons nextStep={() => setStepSelected(8)} previousStep={() => setStepSelected(6)}/>
        </div>
        <PageView
            pageNum={pageNum.current}
            canvasRef={canvasRef}
            zoomFactor={zoomFactor}
            setZoomFactor={setZoomFactor}
            isLoading={formulasQuery.isLoading || pageImage.isLoading}
            showPageMenu={showPageMenu}
            setShowPageMenu={setShowPageMenu}
            pdf={pdf}
            pdfInfo={pdfInfo}
            updateStep={updateStep}
        />
    </div>
};

export default Formulae;
