import {v4 as uuidv4} from 'uuid';

function newTag({elem, modifications, structTree}) {
    if (elem.uuid == null) {
        elem.uuid = uuidv4();
    }
    if (elem.connectedElements == null) {
        elem.connectedElements = [];
    }
    modifications.push({
        type: "NEW_STRUCT_ACTION",
        entries: {
            "S": {
                "type": 4,
                "data": elem.type
            }
        },
        annotation: null,
        tagType: null,
        selection: [elem.children.map(o => o.uuid)],
        position: {"listPosition":  null, "parentId":  null},
        uuid: elem.uuid,
    });
    structTree.push(elem);
}

function updateTagChildren({elem, newChildren, modifications, structTree}) {
    // elem has no id
    if (elem.uuid === null) {
        console.log("Element has no id => something went wrong");
        return;
    }
    // if children are not MCRefs, move elements to structTree
    elem.children.forEach(child => {
        if (child.type !== "MCRef") {
            modifications.push(moveTag({uuid: child.uuid, listPosition: null, parentId: null}));
            structTree.push(child);
        }
    })
    // delete old elem
    deleteTag({elem: elem, modifications: modifications, structTree: structTree});
    // add the new elem
    elem.children = newChildren;
    elem.rectangle = {llx: 99999, lly: 99999, urx: -1, ury: -1, page: elem.rectangle.page};
    newChildren.forEach(o => {
        elem.text += o.text;
        if (elem.rectangle.llx > o.rectangle.llx) elem.rectangle.llx = o.rectangle.llx;
        if (elem.rectangle.urx < o.rectangle.urx) elem.rectangle.urx = o.rectangle.urx;
        if (elem.rectangle.lly > o.rectangle.lly) elem.rectangle.lly = o.rectangle.lly;
        if (elem.rectangle.ury < o.rectangle.ury) elem.rectangle.ury = o.rectangle.ury;
    })
    newTag({
        elem: elem,
        modifications: modifications,
        structTree: structTree
    });
}

function deleteTag({elem, modifications, structTree}) {
    modifications.push({type: "DELETE_STRUCT_ACTION", uuid: elem.uuid});
    let elemI = structTree.findIndex(e => e.uuid === elem.uuid);
    if (elemI !== -1) {
        structTree.splice(elemI, 1);
    }
}

function updateTagType({uuid, newType}) {
    return {
            type: "CHANGE_STRUCT_ACTION",
            entries: {
                "S": {
                    "type": 4,
                    "data": newType
                }
            },
            uuid: uuid,
            overwrite: false,
            annotation: null
        };
}

function updateAltText({uuid, altText}) {
    return {
        type: "CHANGE_ALTTEXT_ACTION",
        altText: altText,
        uuid: uuid
    };
}

function moveTag({uuid, parentUuid, listPosition}) {
    return {
        "type": "REORDER_ACTION",
        "newPos": {
            "listPosition": listPosition,
            "parentUuid": parentUuid
        },
        "uuidToMove": uuid
    }
}

function changeTable({uuid, rows, columns, cellIds, cellTypes, connectFirstRow}) {
    return {
        "type": "CHANGE_TABLE_ACTION",
        "uuid": uuid,
        "rows": rows,
        "columns": columns,
        "cellIds": cellIds,
        "cellTypes": cellTypes,
        "connectFirstRow": connectFirstRow
    }
}

function prepareList({list}) {
    let preparedList = []
    if (list == null) {
        return preparedList;
    }
    list.forEach(li => {
        let listItem = {
            lblChildren: [li.lblChildren.map(lbl => lbl.uuid)],
            lBodyChildren: [li.lBodyChildren.map(lBody => lBody.uuid)],
            sublist: prepareList({list: li.sublist}),
            connectedElements: li.connectedElements,
            uuid: li.uuid
        };
        preparedList.push(listItem);
    });
    return preparedList;
}

function changeList({list}) {
    return {
        "type": "CHANGE_LIST_ACTION",
        "uuid": list[0].baseListUuid,
        "list": prepareList({list: list})
    }
}

function updateConnectedElements({connectedElements}) {
    return {
        "type": "CHANGE_CONNECTED_ELEMENTS_ACTION",
        "connectedElementUuids": connectedElements.map(elem => elem.uuid),
    }
}


export {newTag, updateTagType, deleteTag, moveTag, updateTagChildren, changeTable, updateAltText, changeList, updateConnectedElements}
