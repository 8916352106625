export let mathExpressions = {
    'upperscript with blank exponent': '{#0}^{#0}',
    'lowerscript with blank': '{#0}_{#0}',
    'supercript, exponent': '^{#0}',
    'lowerscript': '_{#0}',
    'upper_lower_script exponent': 'x_{#0}^{#0}',
    'bar over variable': '\\bar{{{#0}}}',
    'arrow vector over variable': '\\vec{{#0}}',
    'tilde over variable': '\\widetilde{{#0}}',
    'breve over variable': '\\breve{{#0}}',
    'dot over variable': '\\dot{{#0}}',
    'wide hat over variable': '\\widehat{{#0}}',
    'check over variable':'\\check{{#0}}',
    'fraction division': '\\frac{{#0}}{{#0}}',
    'squareroot': '\\sqrt{{#0}}',
    'squareroot_nthRoot_radicant': '\\sqrt[{#0}]{#0}',
    'binomial coefficient': '\\left(\\begin{array}{c}{#0}\\\\ {#0}\\end{array}\\right)',
    'limit': '\\lim_{{#0}\\to {#0}}',
    'log_base_argument': '\\log_{#0}{#0}',
    "integral": "\\int",
    "integral_bottom_top": '\\int_{#0}^{#0}',
    'sum': '\\sum',
    'sum_bottom_top': '\\sum_{#0}^{#0}',
    'sum_nolimits': '\\sum\\nolimits_{#0}^{#0}',
    'coproduct': '\\coprod',
    'coproduct_bottom_top': '\\coprod_{#0}^{#0}',
    'product': '\\prod',
    'product_bottom_top': '\\prod_{#0}^{#0}',
    'bigvee or': '\\bigvee',
    'bigvee_bottom_top or': '\\bigvee_{#0}^{#0}',
    'bigwedge and': '\\bigwedge',
    'bigwedge_bottom_top and': '\\bigwedge_{#0}^{#0}',
    'fraction_partial': '\\frac{\\partial {#0}}{\\partial {#0}}',
    /*'derivative': '\\frac{\\text{d}{#0}}{\\text{d}{#0}}',*/
    '2x2 matrix':'\\begin{bmatrix}{{#0}}&{{#0}}\\\\{{#0}}&{{#0}}\\end{bmatrix}',
    /*'2x2 Bmatrix':'\\begin{Bmatrix}{{#0}}&{{#0}}\\\\{{#0}}&{{#0}}\\end{Bmatrix}',
    '2x2 pmatrix':'\\begin{pmatrix}{{#0}}&{{#0}}\\\\{{#0}}&{{#0}}\\end{pmatrix}',*/
    /*'3x3 bmatrix':'\\begin{bmatrix}{{#0}}&{{#0}}&{{#0}}\\\\{{#0}}&{{#0}}&{{#0}}\\\\{{#0}}&{{#0}}&{{#0}}\\end{bmatrix}',*/
    /*'exponent': '{#0}^{#0}',*/
    /*'smth': 'smth',
    'smth': 'smth',
    'smth': 'smth',*/
};


export let symbols = {
    'alpha': '\\alpha',
    'beta': '\\beta ',
    'gamma': '\\gamma ',
    'delta': '\\delta ',
    'epsilon': '\\epsilon ',
    'varepsilon': '\\varepsilon ',
    'zeta': '\\zeta ',
    'eta': '\\eta ',
    'theta': '\\theta ',
    'vartheta': '\\vartheta ',
    'iota': '\\iota ',
    'kappa': '\\kappa ',
    'lambda': '\\lambda ',
    'mu': '\\mu ',
    'nu':'\\nu',
    'xi':'\\xi',
    'pi':'\\pi',
    'rho':'\\rho',
    'varrho':'\\varrho',
    'sigma':'\\sigma',
    'varsigma':'\\varsigma',
    'tau':'\\tau',
    'upsilon':'\\upsilon',
    'phi':'\\phi',
    'varphi':'\\varphi',
    'chi':'\\chi',
    'psi':'\\psi',
    'omega':'\\omega',
    'varpi':'\\varpi',
    'Gamma':'\\Gamma',
    'Delta':'\\Delta',
    'Theta':'\\Theta',
    'Lambda':'\\Lambda',
    'Xi':'\\Xi',
    'Pi':'\\Pi',
    'Sigma':'\\Sigma',
    'Upsilon':'\\Upsilon',
    'Psi':'\\Psi',
    'Omega':'\\Omega',
    'Phi':'\\Phi',
    'infinity': '\\infty',
    //'smth': 'smth',
    //'smth': 'smth',
};

export let operators = {
    'multiplication': '\\cdot',
    'division': '\\div',
    'crossproduct': '\\times',
    'superset': '\\supset',
    'superset_equal': '\\supseteq',
    'subset': '\\subset',
    'subset_equal': '\\subseteq',
    'forall': '\\forall',
    'ni': '\\ni',
    'in': '\\in',
    'notin': '\\notin',
    'exists': '\\exists',
    'not_exists': '\\nexists',
    'plusminus': '\\pm',
    'cup': '\\cup',
    'cap': '\\cap',
    'equal':'=',
    'not equal': '\\neq',
    'equiv':'\\equiv',
    'less equal':'\\leqq',
    'greater equal':'\\geqq',
    'backslash':'\\backslash',
    'left angle':'\\langle',
    'right angle':'\\rangle',
    'left ceil, round up':'\\lceil',
    'right ceil, round up':'\\rceil',
    'left floor round down':'\\lfloor',
    'right floor round down':'\\rfloor',
    'bot':'\\bot',
    'up arrow':'\\uparrow',
    'down arrow':'\\downarrow',
    'right arrow':'\\rightarrow',
    'left arrow':'\\leftarrow',
    'left right arrow': '\\longleftrightarrow',
    'hollow right arrow':'\\Rightarrow',
    'hollow left arrow':'\\Leftarrow',
    'hollow down arrow':'\\Downarrow',
    'hollow up arrow':'\\Uparrow',
    'hollow long left right arrow':'\\Longleftrightarrow',
    /*'':'',
    '':'',*/
}
