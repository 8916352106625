import React, {useEffect, useRef, useState} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useMutation} from "@tanstack/react-query";
import {predictFormula} from "./APIcalls";
import {imageCropper} from "./Tools";
import VirtualKeyboard from "./utils/VirtualKeyboard";
import Instructions from "./utils/Instructions";
import {resizingY, startResizeX} from "./utils/UtilsResize";
import {imageScaling} from "./Config";
import MathLive from 'mathlive';
import {BeatLoader} from "react-spinners";

function MathEditor({img, formula, setUseMathEditor, pdfInfo}) {

    const mathInputRef = useRef(null);
    const latexInputRef = useRef(null);
    const highlightText = useRef(null);
    const SRE = useRef(null);

    const [renderI, setRenderI] = useState(0);

    // resize start values
    const [instructionSize, setInstructionSize] = useState(150);
    const startResizeValueMenu = useRef(null);
    const startResizeValueInstruction = useRef(null);

    useEffect(() => {
        mathInputRef.current = document.getElementById("mathInput");
        mathInputRef.current.disabled = true;
        latexInputRef.current = document.getElementById("latexInput");
        setRenderI(prevState => prevState + 1);
        predictMERMutation.mutate({pdfInfo: pdfInfo, formulaId: formula.uuid});
        SRE.current = window.MathJax._.a11y.sre.Sre;
    }, []);

    // predict alt text
    const predictMERMutation = useMutation({
        mutationFn: ({pdfInfo, formulaId}) => predictFormula({pdfInfo: pdfInfo, formulaId: formulaId}),
        onSuccess: (data) => {
            formula.altText = data.data;
            mathInputRef.current.value = formula.altText;
            latexInputRef.current.value = formula.altText;
            mathInputRef.current.disabled = false;
            setRenderI(prevState => prevState + 1);
        },
        onError: error => {
            mathInputRef.current.value = formula.altText;
            latexInputRef.current.value = formula.altText;
            mathInputRef.current.disabled = false;
            setRenderI(prevState => prevState + 1);
        }
    })

    function latexEditorChange() {
        formula.altText = latexInputRef.current.value;
        mathInputRef.current.value = formula.altText;
    }

    function latexEditorSelection() {
        //highlightText.current = [latexInputRef.current.selectionStart, latexInputRef.current.selectionEnd];
        //setRenderI(prevState => prevState + 1);
    }

    function mathEditorChange() {
        formula.altText = mathInputRef.current.value;
        latexInputRef.current.value = prepareFormula(formula.altText);
    }

    function mathEditorSelection() {
        highlightText.current = mathInputRef.current.selection.ranges[0];
        if (highlightText.current[0] === highlightText.current[1]) return;
        latexInputRef.current.focus();
        latexInputRef.current.setSelectionRange(highlightText.current[0], highlightText.current[1] + 1);
        setRenderI(prevState => prevState + 1);
    }

    useEffect(() => {
        return () => {
            //mathInputRef.current.applyStyle({ color: "red" }, { range: highlightText.current });
            //latexInputRef.current.applyStyle({color: "red", fontSize: 7 }, { range: highlightText.current });
            setRenderI(prevState => prevState + 1);
        };
    }, [highlightText.current]);

    function prepareFormula(formulaText) {
        const highlightingExpression = "\\textcolor{red}{";
        if (formulaText.includes(highlightingExpression)) {
            const start = formulaText.indexOf(highlightingExpression);
            const startExpression = start + highlightingExpression.length;
            const endExpression = startExpression + highlightText.current[1] - highlightingExpression[0];
            return formulaText.slice(0, start) + "<mark>" + formulaText.slice(startExpression, endExpression) + "</mark>" + formulaText.slice(endExpression + 1,  -1);
        }
        return formulaText;
    }

    function convertLatexToMathSpeak(latex) {
        SRE.current.setupEngine(
            {locale: "en", domain: "mathspeak", modality: "speech",
                style: "default", markup: "none", pprint: true
            })
            .then(() => {
                formula.altText = SRE.current.toSpeech(window.MathJax.tex2mml(latex));
            })
            .then(() => {
                setUseMathEditor(false);
            });
    }


    return (
        <div className="menu mathEditor"
             onMouseUp={() => startResizeValueInstruction.current = null}
             onMouseMove={(e) => resizingY(e, startResizeValueInstruction.current, setInstructionSize)}>
            <div className="resizeHandleX" onMouseDown={(e) => startResizeValueMenu.current = startResizeX(e)}></div>
            <Instructions
                title="Math Editor"
                step="MathEditor"
            />
            <div className="blueBox flex-fill">
                <div className="mathEditorFirstRow">
                    <div className="whiteBox originalImageArea align-items-center">
                        <div className="figureImgDiv">
                            <img src={imageCropper({
                                image: img,
                                border: 5,
                                rectangle: {
                                    x: formula.rectangle.llx * imageScaling,
                                    y: img.naturalHeight - formula.rectangle.ury * imageScaling,
                                    w: formula.rectangle.urx * imageScaling - formula.rectangle.llx * imageScaling,
                                    h: formula.rectangle.ury * imageScaling - formula.rectangle.lly * imageScaling
                                },
                                resize: 1})}/>
                        </div>
                    </div>
                    <div className="whiteBox flex-fill position-relative">
                        <Form className="latexInputArea">
                            <Form.Group>
                                <Form.Control type="alternative text" as="textarea" rows={5} className="latexInput" id="latexInput" 
                                              defaultValue={formula.altText} onChange={latexEditorChange}
                                              onMouseUp={latexEditorSelection} disabled={predictMERMutation.isLoading}/>
                            </Form.Group>
                        </Form>
                        {predictMERMutation.isLoading ? <BeatLoader className="loadingSymbol" /> : null}
                    </div>
                </div>
                <div className="whiteBox flex-column flex-fill">
                    <div className="mathInputArea">
                        <math-field id="mathInput" onInput={mathEditorChange}>
                        </math-field>
                        {predictMERMutation.isLoading ? <BeatLoader className="loadingSymbol" /> : null}
                    </div>
                    <VirtualKeyboard mathInputRef={mathInputRef.current}/>
                </div>
            </div>
            <div className="buttonRow">
                <Button onClick={() => {
                    try {
                        convertLatexToMathSpeak(formula.altText);
                    } catch (e) {
                        console.log(e);
                    }
                }} variant="light" className="actionButton">Generate Alternative Text</Button>
            </div>
        </div>
    );
}

export default MathEditor;
