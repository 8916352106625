import React from 'react';
import LanguageButton from "./LanguageButton";
import Button from 'react-bootstrap/Button';
import { IoMdHelpCircleOutline, IoMdContact } from 'react-icons/io';
import { IoSchool } from 'react-icons/io5';
import {ImSection} from "react-icons/im";

const Header = ({title, language, changeLanguage}) => {
    return (
        <div className="d-flex flex-row align-items-center" id="header">
            <Button onClick={() => {
                localStorage.removeItem("pdf");
                localStorage.removeItem("task");
                window.location.href = '/';
            }} id="headerButton">
                <img src="img/logo.png" width="38" height="49" alt="Logo von PAVE"></img>
                <h1>PAVE 2.0</h1>
            </Button>
            <div className="d-flex flex-fill justify-content-center" style={{textAlign: "center"}}>
                {title}
            </div>

            <div className="d-flex flex-row">
                <Button onClick={() => window.open('http://www.zhaw.ch/en', '_blank')}><IoSchool size={20} />ZHAW</Button>
                <Button onClick={() => window.open('/contact')}><IoMdContact size={20} />Contact</Button>
                <Button onClick={() => window.open('/help')}><IoMdHelpCircleOutline size={20} />Help/FAQ</Button>
                <Button onClick={() => window.open('/legal')}><ImSection size={20} />Legal Information</Button>
                <LanguageButton language={language} changeLanguage={changeLanguage}/>
            </div>
        </div>
    );
};

export default Header;
