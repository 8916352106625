import React, {useEffect, useState} from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {mathExpressions, operators, symbols} from "./listOfSpecialCharacters";
import Button from "react-bootstrap/Button";
import katex from "katex";
import {FaArrowDown, FaArrowUp} from "react-icons/fa";

// https://blog.logrocket.com/how-to-build-tab-component-react/
export const VirtualKeyboard = ({mathInputRef}) => {

    const [search, setSearch] = useState('');
    const [showVirtualKeyboard, setShowVirtualKeyboard] = useState(false);

    function renderLatex(latexString){
        while (latexString.indexOf('{#0}') !== -1){
            latexString = latexString.replace('{#0}', 'x')}
        try {

            return { __html: katex.renderToString(latexString, {
                    throwOnError: false,
                    displayMode: true,
                    errorColor: '#cc0000',
                    macros: {"\\R": "\\mathbb{R}"}, // \mathbb{R} = \R
                    colorIsTextColor: false,

                })};
        } catch (error) {
            // KaTeX can't parse the expression
            return { __html: error.message };
        }
    }

    function insertKeyItem(latexString){
        mathInputRef().executeCommand(['insert', latexString]);
    }

    function getButton(key, dictionary, i, search) {
        if (search === '') {
            return <Button key={i} className="keyboardItem" onClick={() => insertKeyItem(dictionary[key])} variant="light"><span dangerouslySetInnerHTML={renderLatex(dictionary[key])} /></Button>
        }
        if (key.includes(search)) {
            return <Button key={i} className="keyboardItem" onClick={() => insertKeyItem(dictionary[key])} variant="light"><span dangerouslySetInnerHTML={renderLatex(dictionary[key])} /></Button>
        }
        return null;
    }

    return (
        <div className="whiteBox virtualKeyboard">
            <Button variant="light" onClick={() => setShowVirtualKeyboard(prev => !prev)} className="showVirtualKeyboard" aria-label="Show virtual keyboard">
                {showVirtualKeyboard ? <><FaArrowDown /><br/>Hide Keyboard</> :<><FaArrowUp /><br/>Show Keyboard</>}
            </Button>
            {showVirtualKeyboard ? <>
                <input className='searchBar' placeholder={'search...'} onChange={e => setSearch(e.target.value)}/>
                <Tabs
                    defaultActiveKey="Mathematical Expression"
                >
                    <Tab eventKey="Mathematical Expression" title="Mathematical Expression">
                        <div className="mathKeyboardTab">
                            {Object.keys(mathExpressions).map((key, i) => getButton(key, mathExpressions, i, search))}
                        </div>
                    </Tab>
                    <Tab eventKey="Operators" title="Operators" className="mathKeyboardTab">
                        <div className="mathKeyboardTab">
                            {Object.keys(operators).map((key, i) => getButton(key, operators, i, search))}
                        </div>
                    </Tab>
                    <Tab eventKey="Symbols" title="Symbols" className="mathKeyboardTab">
                        <div className="mathKeyboardTab">
                            {Object.keys(symbols).map((key, i) => getButton(key, symbols, i, search))}
                        </div>
                    </Tab>
                </Tabs>
            </> : null}
                </div>
                )
            }

export default VirtualKeyboard;
