import React from "react";
import {Link} from "react-router-dom";

const Step4 = () => {
    return <div className="instructionsText">
        <Link to="/help#whyDoIHaveToDefineTheTableStructure" target={"_blank"}>More information about tables</Link>.
        <ul>
            <li><strong>Add new rows and columns:</strong> Draw lines in the page view to separate rows and
                columns. You can see the current table structure in the blue box.</li>
            <li><strong>Delete rows and columns:</strong> Select the line you want to delete in the page view and click
                <em>Combine Rows/Columns</em></li>
            <li><strong>Delete the existing table structure:</strong> Select <em>Delete All Table Cells</em> to start from scratch
                with the table structure.</li>
        </ul>
    </div>
}

export default Step4;
