import React, {useEffect, useRef, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getPageImagePdfJs, getTagType, updateTags} from "./APIcalls";
import {tableConfig} from "./Config";
import Button from "react-bootstrap/Button";
import {AiOutlineDelete} from "react-icons/ai";
import {
    mode,
    getMousePos,
    transformRectangle,
    navigation,
    processConnectedElements,
    changeElementPartI, changeElementI, pointIsInside, getElement
} from "./Tools";
import {changeTable} from "./StructTreeActions";
import {loadingCanvas, drawTables, drawFigures} from "./Drawing";
import {ButtonGroup, Table} from "react-bootstrap";
import {
    checkHeaderCells,
    prepareTable,
    fixTableDimensions,
    getProcessedTable,
    removeEmptyRowsColumns, connectRows
} from "./utils/UtilsTables";
import Instructions from "./utils/Instructions";
import {errorDrawing, WarningNotComplete} from "./utils/ErrorMessages";
import {VscCombine} from "react-icons/vsc";
import {resizingX, resizingY, startResizeX} from "./utils/UtilsResize";
import PageView from "./PageView";
import StepNavigationButtons from "./StepNavigationButtons";

const Tables = ({pdf, pdfInfo, stepSelected, setStep, menuSize, setMenuSize, showPageMenu, setShowPageMenu, setStepSelected}) => {
    const tables = useRef([]);
    const [tableI, setTableI] = useState(-1);
    const [tablePartI, setTablePartI] = useState(0);
    const pageNum = useRef(1);
    const [imageReady, setImageReady] = useState(true);
    const modifications = useRef([]);

    // canvas table
    const canvasRef = useRef(null);
    const ctx = useRef(null);
    const canvasDrawing = useRef(false);

    // mouse position
    const curPos = useRef(0);
    const mouseMove = useRef(null);

    // Table
    const tableDimensions = useRef({});
    const tableElementSelected = useRef(false);
    const [renderI, setRenderI] = useState(0);
    const highlightCell = useRef(false);
    const headerCells = useRef("row");

    const queryClient = useQueryClient();

    const viewed = useRef([true]);
    const [showWarning, setShowWarning] = useState(false);

    const [zoomFactor, setZoomFactor] = useState(1);

    // resize start values
    const startResizeValueMenu = useRef(null);

    // init canvas
    useEffect(() => {
        if (canvasRef.current) {
            ctx.current = canvasRef.current.getContext('2d');
        }
        tablesQuery.refetch();
        viewed.current[tableI] = true;
    }, []);

    // changing step
    useEffect(() => {
        if (stepSelected !== -1) {
            const checkedAll = viewed.current.every(t => t || stepSelected < 4);
            updateStep(() => {
                if (checkedAll) setStep(stepSelected);
                else setShowWarning(true);
            });
        }
    }, [stepSelected]);

    // get the tables
    const tablesQuery = useQuery({
        queryKey: ["Tables", pdfInfo.fileid],
        queryFn: () => getTagType({pdfInfo: pdfInfo, tagType: "Table", pdf: pdf}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: data => {
            tables.current = data.map(table => {
                table.processedTable = fixTableDimensions(prepareTable({tableArray: [], elements: table.children, modifications: modifications.current}));
                return table;
            });
            tables.current = tables.current.filter(table => table.processedTable.length > 0);
            if (tables.current.length > 0) {

                // process header cells
                tables.current.forEach(table => checkHeaderCells({processedTable: table.processedTable}));
                pageNum.current = tables.current[0].rectangle.page;

                // process connected elements
                tables.current = processConnectedElements(tables.current);

                // process table headers
                if (mode(getProcessedTable(tables.current[0])[0].map(column => column.type)) === "TH") {
                    if (mode(getProcessedTable(tables.current[0]).map(column => column[0].type)) === "TH") {
                        headerCells.current = "both";
                    }
                    else {
                        headerCells.current = "row";
                    }
                }
                else {
                    headerCells.current = "column";
                }
                changeTableHeader({processedTable: getProcessedTable(tables.current[0])});

                // process connected rows
                tables.current.forEach(table => connectRows(table));

                setTableI(0);

                if (viewed.current.length === 1) {
                    viewed.current = Array.from({length: tables.current.length}, _ => false);
                }
                viewed.current[0] = true;
            }
        }
    });

    // get the image
    const pageImage = useQuery({
        queryKey: ["image", pdfInfo.fileid, pageNum.current],
        queryFn: () => getPageImagePdfJs({pdf: pdf, pageNum: pageNum.current, setImageReady: setImageReady}), // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: () => drawing()
    });

    // update tables
    const tablesMutation = useMutation({
        mutationFn: ({pdfInfo, modifications}) => updateTags({pdfInfo: pdfInfo, modifications: modifications})
    })

    function onMouseDown(e) {
        // do nothing during loading
        if (tablesQuery.isLoading || pageImage.isLoading) return
        canvasDrawing.current = true;
        tableElementSelected.current = null;
        curPos.current = getMousePos({e: e, canvasRef: canvasRef.current});
        mouseMove.current = {startX: curPos.current.x, startY: curPos.current.y};
    }

    function onMouseMove(e) {
        // do nothing during loading
        if (tablesQuery.isLoading || pageImage.isLoading) return
        curPos.current = getMousePos({e: e, canvasRef: canvasRef.current})
        if (canvasDrawing.current) {
            mouseMove.current.endX = curPos.current.x;
            mouseMove.current.endY = curPos.current.y;
        }
        requestAnimationFrame(drawing)
    }

    function onMouseUp(e) {
        // do nothing during loading
        if (tablesQuery.isLoading || pageImage.isLoading) return
        canvasDrawing.current = false;
        processLine();
        mouseMove.current = null;
        tableElementSelected.current = null;
        drawing();
    }

    function onMouseClick(e) {
        // do nothing during loading
        if (tablesQuery.isLoading || pageImage.isLoading) return

        // get mouse position and check if a line selected
        curPos.current = getMousePos({e: e, canvasRef: canvasRef.current});

        let table = getElement(tables.current, tableI, tablePartI);

        // check if inside of table
        if (!pointIsInside(curPos.current, transformRectangle({rect: table.rectangle, imageSize: [pageImage.data.naturalWidth, pageImage.data.naturalHeight]}), tableConfig.borderSize)) {
            tableElementSelected.current = null;
            return;
        }

        // check if row line
        tableDimensions.current.rowY.every((rowLine, rowI) => {
            // do not select row if it is a connected row, first row or selected row
            if ((rowI === 0 && (table.processedTable[0][0].connectFirstRow || tablePartI < 1)) ||
                (rowI === table.processedTable.length && (table.processedTable[table.processedTable.length - 1][0].connectLastRow || tablePartI === table.connectedElements.length - 1 || table.connectedElements.length < 2))
            ) {
                return true;
            }
            // check if row line selected
            if (rowLine - tableConfig.lineSelectionOffset < curPos.current.y && curPos.current.y < rowLine + tableConfig.lineSelectionOffset) {
                tableElementSelected.current = {rowLine: rowI};
                return false;
            }
            return true
        })

        //check if column line
        if (tableElementSelected.current == null || tableElementSelected.current.rowLine == null) {
            //check column
            tableDimensions.current.columnX.every((columnLine, columnI) => {
                if (columnI === 0 || columnI === tableDimensions.current.columnX.length - 1) {
                    return true;
                }
                // check if row line selected
                if (columnLine - tableConfig.lineSelectionOffset < curPos.current.x && curPos.current.x < columnLine + tableConfig.lineSelectionOffset) {
                    tableElementSelected.current = {columnLine: columnI};
                    return false;
                }
                return true
            })
        }
        setRenderI(prevState => prevState + 1);
        drawing();
    }

    function processLine() {
        const dx = Math.abs(mouseMove.current.endX - mouseMove.current.startX);
        const dy = Math.abs(mouseMove.current.endY - mouseMove.current.startY);
        let table = getElement(tables.current, tableI, tablePartI);
        if (dx < tableConfig.borderSize && dy < tableConfig.borderSize) {
            drawing();
            return;
        }
        if (dx > dy) {
            // new row

            // check if above table and remove connected rows
            if (tables.current[tableI].connectedElements.length > 1 && tablePartI !== 0 &&
                mouseMove.current.startY < tableDimensions.current.rowY[0] &&
                mouseMove.current.endY < tableDimensions.current.rowY[0]) {
                table.connectFirstRow = false;
                tables.current[tableI].connectedElements[tablePartI - 1].connectLastRow = false;
            }

            // check if below table and remove connected rows
            if (tables.current[tableI].connectedElements.length > 1 && tablePartI < table.connectedElements.length - 1 &&
                mouseMove.current.startY > tableDimensions.current.rowY[tableDimensions.current.rowY.length - 1] &&
                mouseMove.current.endY > tableDimensions.current.rowY[tableDimensions.current.rowY.length - 1]) {
                table.connectLastRow = false;
                tables.current[tableI].connectedElements[tablePartI + 1].connectFirstRow = false;
            }

            // check that line is valid
            let rowI = null;
            for (let i = 0; i < tableDimensions.current.rowY.length - 1; i++) {
                if (tableDimensions.current.rowY[i] < mouseMove.current.startY &&
                    mouseMove.current.startY < tableDimensions.current.rowY[i + 1] &&
                    tableDimensions.current.rowY[i] < mouseMove.current.endY &&
                    mouseMove.current.endY < tableDimensions.current.rowY[i + 1]) {
                    rowI = i;
                    break;
                }
            }

            // add the new row
            if (rowI != null) {
                // iterate over the cells of rowI
                let moveElements = [];
                for (let columnI = 0; columnI < table.processedTable[rowI].length; columnI++) {
                    moveElements.push([]);
                    // iterate over the elements in a cell
                    table.processedTable[rowI][columnI].elements.forEach((element, elementI) => {
                        // determine if cell is above line or below
                        const elementRect = transformRectangle({rect: element.rectangle, imageSize: [pageImage.data.naturalWidth, pageImage.data.naturalHeight]})
                        if (elementRect.y + elementRect.h / 2 < (mouseMove.current.startY + (mouseMove.current.endY - mouseMove.current.startY) * (elementRect.x + elementRect.w / 2 - mouseMove.current.startX) / (mouseMove.current.endX - mouseMove.current.startX))) {
                            // above line
                            moveElements[columnI].push(elementI);
                        }
                    });
                }
                table.processedTable.splice(rowI, 0, []);
                // move the elements
                for (let columnI = 0; columnI < table.processedTable[rowI + 1].length; columnI++) {
                    table.processedTable[rowI][columnI] = {type: "TD", elements: []};
                    moveElements[columnI].forEach((moveI, i) => {
                        table.processedTable[rowI][columnI].elements.push(table.processedTable[rowI + 1][columnI].elements[moveI - i]);
                        table.processedTable[rowI + 1][columnI].elements.splice(moveI - i, 1);
                    });
                }
            }

        }
        else {
            // new column
            // check that line is valid
            let columnI = null;
            for (let i = 0; i < tableDimensions.current.columnX.length - 1; i++) {
                if (tableDimensions.current.columnX[i] < mouseMove.current.startX && mouseMove.current.startX < tableDimensions.current.columnX[i + 1] && tableDimensions.current.columnX[i] < mouseMove.current.endX && mouseMove.current.endX < tableDimensions.current.columnX[i + 1]) {
                    columnI = i;
                    break;
                }
            }

            // add the new column
            if (columnI != null) {
                let moveElements = [];
                table.processedTable.forEach((row, rowI) => {
                    moveElements.push([])
                    // iterate over the elements in a cell
                    table.processedTable[rowI][columnI].elements.forEach((element, elementI) => {
                        // determine if cell is right or left of line
                        const elementRect = transformRectangle({rect: element.rectangle, imageSize: [pageImage.data.naturalWidth, pageImage.data.naturalHeight]})
                        if (elementRect.x + elementRect.w / 2 < (mouseMove.current.startX + (mouseMove.current.endX - mouseMove.current.startX) * (elementRect.y + elementRect.h / 2 - mouseMove.current.startY) / (mouseMove.current.endY - mouseMove.current.startY))) {
                            // above line
                            moveElements[rowI].push(elementI);
                        }
                    })
                })
                // move the elements
                table.processedTable.forEach((row, rowI) => {
                    row.splice(columnI, 0, []);
                    table.processedTable[rowI][columnI] = {type: "TD", elements: []};
                    moveElements[rowI].forEach((moveI, i) => {
                        table.processedTable[rowI][columnI].elements.push(table.processedTable[rowI][columnI + 1].elements[moveI - i]);
                        table.processedTable[rowI][columnI + 1].elements.splice(moveI - i, 1);
                    });
                });
            }
        }
        removeEmptyRowsColumns(tables.current[tableI]);
        drawing();
        changeTableHeader({processedTable: getProcessedTable(tables.current[tableI])});
    }

    function changeTableHeader({processedTable}) {
        processedTable.forEach((row, rowI) => {
            row.forEach((column, columnI) => {
                if ((rowI === 0 && (headerCells.current === "row" || headerCells.current === "both")) ||
                    (columnI === 0 && (headerCells.current === "column" || headerCells.current === "both"))) {
                    column.type = "TH";
                }
                else {
                    column.type = "TD";
                }
            })
        })
        drawing();
        setRenderI(prevState => prevState + 1);
    }

    /**
     * Helper function for updating the step
     */
    function updateStep(onSuccessFunction) {
        if (tables.current) {
            tables.current.forEach(table => {
                if (table.processedTable != null) {
                    if (table.connectedElements.length > 1) {
                        table.connectedElements.forEach(t => {
                            modifications.current.push(changeTable({
                                uuid: t.uuid,
                                rows: t.processedTable.length,
                                columns: t.processedTable[0].length,
                                cellIds: [].concat.apply([], t.processedTable.map(row => {
                                    return row.map(cell => {
                                        return [cell.elements.map(e => e.uuid)];
                                    });
                                })),
                                cellTypes: [].concat.apply([], t.processedTable.map(row => {
                                    return row.map(cell => {
                                        return cell.type;
                                    })
                                })),
                                connectFirstRow: t.connectFirstRow,
                            }));
                        })
                    }
                    else {
                        modifications.current.push(changeTable({
                            uuid: table.uuid,
                            rows: table.processedTable.length,
                            columns: table.processedTable[0].length,
                            cellIds: [].concat.apply([], table.processedTable.map(row => {
                                return row.map(cell => {
                                    return [cell.elements.map(e => e.uuid)];
                                });
                            })),
                            cellTypes: [].concat.apply([], table.processedTable.map(row => {
                                return row.map(cell => {
                                    return cell.type;
                                })
                            }))
                        }));
                    }
                }
            })
        }
        return tablesMutation.mutate({pdfInfo: pdfInfo, modifications: modifications.current}, {
            onSuccess: () => {
                queryClient.invalidateQueries(["Tables", pdfInfo.fileid]).then(() => {
                    if (onSuccessFunction != null) {
                        onSuccessFunction();
                    }
                });
            }
        });
    }


    /**
     * helper function for drawing
     */
    function drawing() {
        if (!tablesQuery.isLoading && tables.current.length > 0 && imageReady && ctx.current != null && pageImage.data != null) {
            try {
                canvasRef.current.height = pageImage.data.naturalHeight;
                canvasRef.current.width = pageImage.data.naturalWidth;
                let table = tables.current[tableI];
                let startRow = 0;
                if (tables.current[tableI] != null && tables.current[tableI].connectedElements.length > 0) {
                    table = tables.current[tableI].connectedElements[tablePartI];
                    for (let i = 0; i < tablePartI; i++) {
                        startRow += tables.current[tableI].connectedElements[0].processedTable.length;
                        if (tables.current[tableI].connectedElements[0].processedTable[tables.current[tableI].connectedElements[0].processedTable.length - 1][0].connectLastRow) {
                            startRow -= 1;
                        }
                    }
                }
                tableDimensions.current = drawTables({
                    ctx: ctx.current,
                    image: pageImage.data,
                    table: table,
                    tableElementSelected: tableElementSelected.current,
                    mouseMove: mouseMove.current,
                    highlightCell: highlightCell.current,
                    startRow: startRow,
                });
            }
            catch (e) {
                errorDrawing({errorMessage: e});
            }
        }
        else if (tablesQuery.isLoading || pageImage.isLoading) {
            loadingCanvas({ctx: ctx.current, canvasRef: canvasRef.current});
        }
        else if (imageReady && ctx.current != null && pageImage.data != null) {
            canvasRef.current.height = pageImage.data.naturalHeight;
            canvasRef.current.width = pageImage.data.naturalWidth;
            drawFigures({ctx: ctx.current, figure: null, image: pageImage.data});
        }
    }
    drawing();

    function changeTableCallback(i) {
        if (mode(getProcessedTable(tables.current[i]).map(column => column.type)) === "TH") {
            if (mode(getProcessedTable(tables.current[i]).map(column => column[0].type)) === "TH") {
                headerCells.current = "both";
            }
            else {
                headerCells.current = "row";
            }
        }
        else {
            headerCells.current = "column";
        }
        changeTableHeader({processedTable: getProcessedTable(tables.current[tableI])});
        tableElementSelected.current = null;
    }

    function changeTablePartICallback(i) {
        if (mode(tables.current[tableI].connectedElements[i].processedTable[0].map(column => column.type)) === "TH") {
            if (mode(tables.current[tableI].connectedElements[i].processedTable.map(column => column[0].type)) === "TH") {
                headerCells.current = "both";
            }
            else {
                headerCells.current = "row";
            }
        }
        else {
            headerCells.current = "column";
        }
        changeTableHeader({processedTable: getProcessedTable(tables.current[tableI])});
        tableElementSelected.current = null;
    }

    function deleteLine() {
        let table = getElement(tables.current, tableI, tablePartI);
        if (tableElementSelected.current.rowLine != null) {
            // if connect first rows
            if (tableElementSelected.current.rowLine === 0 && tablePartI !== 0) {
                table.connectFirstRow = true;
                tables.current[tableI].connectedElements[tablePartI - 1].connectLastRow = true;
            }
            // if connect last row
            else if (tableElementSelected.current.rowLine === table.processedTable.length && tablePartI !== table.connectedElements.length - 1) {
                table.connectLastRow = true;
                tables.current[tableI].connectedElements[tablePartI + 1].connectFirstRow = true;
            }
            else {
                table.processedTable[tableElementSelected.current.rowLine - 1].forEach((column, columnI) => {
                    column.elements = column.elements.concat(table.processedTable[tableElementSelected.current.rowLine][columnI].elements);
                })
                table.processedTable.splice(tableElementSelected.current.rowLine, 1);
            }
        }
        if (tableElementSelected.current.columnLine != null) {
            table.processedTable.forEach(row => {
                row[tableElementSelected.current.columnLine - 1].elements = row[tableElementSelected.current.columnLine - 1].elements.concat(row[tableElementSelected.current.columnLine].elements);
                row.splice(tableElementSelected.current.columnLine, 1);
            })
        }
        removeEmptyRowsColumns(tables.current[tableI]);
        tableElementSelected.current = null;
        setRenderI(prevState => prevState + 1);
        changeTableHeader({processedTable: getProcessedTable(tables.current[tableI])});
    }

    function resetTable() {
        let table = getElement(tables.current, tableI, tablePartI);
        const tableElements = table.processedTable.map(row => row.map(column => column.elements));
        table.processedTable = [[{elements: tableElements.flat(2), type: "TD"}]];
    }

    function tableAsHtml({table}) {
        table = getProcessedTable(table);
        let connectCorrection = 0;
        return <Table>
            <tbody>
                <tr key={-1}>
                    <td className="borderCell">
                        <div></div>
                    </td>
                    {table[0].map((column, columnI) => {
                        return <td key={[-1, columnI].toString()} className="borderCell">
                            <div>{String.fromCharCode(65 + columnI)}</div>
                        </td>
                    })}
                </tr>
                {table.map((row, rowI) => {
                    if (row[0].connectFirstRow) {
                        connectCorrection += 1;
                    }
                    return <tr key={rowI}>
                        <td key={[rowI, -1].toString()} className={"borderCell" + (row[0].connectFirstRow ? " tableCellConnectFirstRow" : " ") + (row[0].connectLastRow ? " tableCellConnectLastRow" : " ")}>
                            <div>{rowI + 1 - connectCorrection}</div>
                        </td>
                        {row.map( (column, columnI) => {
                            if (column.type === "TH") {
                                return <th key={[rowI, columnI].toString()} className={"tableCellTd" + (column.connectFirstRow ? " tableCellConnectFirstRow" : " ") + (column.connectLastRow ? " tableCellConnectLastRow" : " ")}>
                                    <div
                                         onMouseEnter={() => {
                                             highlightCell.current = [rowI, columnI];
                                             requestAnimationFrame(drawing);
                                         }}
                                         onMouseLeave={() => {
                                             highlightCell.current = false;
                                             requestAnimationFrame(drawing);
                                         }}>{column.elements.map(e => e.text)}</div>
                                </th>
                            }
                            else {
                                return <td key={[rowI, columnI].toString()} className={"tableCellTh" + (column.connectFirstRow ? " tableCellConnectFirstRow" : " ") + (column.connectLastRow ? " tableCellConnectLastRow" : " ")}>
                                    <div onMouseEnter={() => {
                                        highlightCell.current = [rowI, columnI];
                                        requestAnimationFrame(drawing);
                                    }}
                                         onMouseLeave={() => {
                                             highlightCell.current = false;
                                             requestAnimationFrame(drawing);
                                         }}>{column.elements.map(e => e.text)}</div>
                                </td>
                            }
                        })}
                    </tr>
                })}
            </tbody>
        </Table>
    }

    return (
        <div className="workspaceArea"
             onMouseUp={() => startResizeValueMenu.current = null}
             onMouseMove={(e) => resizingX(e, startResizeValueMenu.current, setMenuSize)}
             style={startResizeValueMenu.current != null ? {userSelect: 'none'}: null}>
            <WarningNotComplete
                showWarning={showWarning} setShowWarning={setShowWarning}
                titleMessage={"Have you checked all tables?"}
                bodyMessage={<>It seems that you have not checked the following tables: {viewed.current.map((e, ei) => e ? null : ei + 1).filter(e => e != null).join(", ")} <br /> Do you want to check them before you continue?</>}
                currentTask={4}
                setTask={setStep}
                taskSelected={stepSelected}/>
            <div id="menu" className="menu" style={{width: menuSize + "px"}}>
                <div className="resizeHandleX" onMouseDown={(e) => startResizeValueMenu.current = startResizeX(e)}></div>
                <Instructions
                    title="Step 4: Define Table Structure"
                    step={4}
                />
                {navigation({
                    callback: (i) => pageNum.current = changeElementI({
                        i: i,
                        elementI: tableI,
                        pageNum: pageNum.current,
                        elements: tables.current,
                        setElementI: setTableI,
                        setElementPartI: setTablePartI,
                        callback: changeTableCallback
                    }),
                    length: tables.current.length,
                    i: tableI,
                    title: "Tables",
                    viewed: viewed.current
                })}
                <div className="buttonRow">
                    <Button variant="light" onClick={deleteLine}
                            disabled={!(tableElementSelected.current != null || tableElementSelected.current === false)} className="actionButton">
                        <VscCombine size="1.5em"/> <br/>
                        Combine Rows/Columns
                    </Button>
                    <Button variant="light" onClick={resetTable} disabled={tables.current.length === 0} className="actionButton">
                        <AiOutlineDelete size="1.5em"/> <br/>
                        Delete All Table Cells
                    </Button>
                </div>
                <div className="blueBox">
                    <h3>{tables.current.length > 0 ? "Table Structure" : "No table found in the document"}</h3>
                    {tables.current.length > 0 ? <div className="headingCellButtons">
                        {"Heading Cells:  "}
                        <ButtonGroup>
                            <Button active={headerCells.current === "row"} onClick={() => {
                                headerCells.current = "row";
                                changeTableHeader({processedTable: getProcessedTable(tables.current[tableI])});
                            }} variant="light">First Row</Button>
                            <Button active={headerCells.current === "column"} onClick={() => {
                                headerCells.current = "column";
                                changeTableHeader({processedTable: getProcessedTable(tables.current[tableI])});
                            }} variant="light">First Column</Button>
                            <Button active={headerCells.current === "both"} onClick={() => {
                                headerCells.current = "both";
                                changeTableHeader({processedTable: getProcessedTable(tables.current[tableI])});
                            }} variant="light">Both</Button>
                        </ButtonGroup>
                        </div> : null}
                    {tables.current != null && tables.current.length > 0 ? <>
                        {tables.current[tableI].connectedElements.length > 1 ? navigation({
                            callback: (i) => pageNum.current = changeElementPartI({
                                i: i,
                                elementI: tableI,
                                elementPartI: tablePartI,
                                pageNum: pageNum.current,
                                elements: tables.current,
                                setElementPartI: setTablePartI,
                                callback: changeTablePartICallback,
                            }),
                            length: tables.current[tableI].connectedElements.length,
                            i: tablePartI,
                            title: "Parts",
                            viewed: [],
                            parts: true
                        }) : null}
                        <div id="tableAsHtml">
                            {tableAsHtml({table: tables.current[tableI]})}
                        </div>
                    </> : null}
                </div>
                <StepNavigationButtons nextStep={() => setStepSelected(5)} previousStep={() => setStepSelected(3)}/>
            </div>
            <PageView
                pageNum={pageNum.current}
                canvasRef={canvasRef}
                onMouseClick={onMouseClick}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                zoomFactor={zoomFactor}
                setZoomFactor={setZoomFactor}
                isLoading={tablesQuery.isLoading || pageImage.isLoading}
                showPageMenu={showPageMenu}
                setShowPageMenu={setShowPageMenu}
                pdf={pdf}
                pdfInfo={pdfInfo}
                updateStep={updateStep}
            />
        </div>
    );
};

export default Tables;
