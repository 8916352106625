import React from "react";
import {Link} from "react-router-dom";

const Step8 = () => {
    return <div className="instructionsText">
        <Link to="/help#whyDoIHaveToAddMetaInformation" target={"_blank"}>More information about meta information</Link>.
        <ul>
            <li><strong>Modify meta information:</strong> Title, author, and language must be set.</li>
            <li><strong>Checking tagged PDF:</strong> Use the page view to check your tagged PDF. You can correct errors by
                going back to the previous steps.</li>
        </ul>
    </div>
}

export default Step8;
