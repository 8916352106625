import React from "react";

const Step0 = () => {
    return <div className="instructionsText">
        <ul>
            <li><strong>New PDF:</strong> Drag and Drop your PDF or select it.</li>
            <li><strong>Continue on PDF:</strong> Select the PDF from the list (your PDFs are saved for 24 hours)</li>
        </ul>
    </div>
}

export default Step0;
