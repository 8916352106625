import React, {useEffect, useState, useRef} from 'react';
import {getMetaData, getPageImagePdfJs, getPageStructTree, updateMetaData} from "./APIcalls";
import {useQuery, useMutation, useQueryClient} from "@tanstack/react-query";

import Form from 'react-bootstrap/Form';
import DropdownItem from "react-bootstrap/DropdownItem";
import DropdownButton from "react-bootstrap/DropdownButton";
import {metaInformationConfig} from "./Config";
import Button from "react-bootstrap/Button";
import { AiOutlineDownload } from 'react-icons/ai';
import Instructions from "./utils/Instructions";
import {navigation} from "./Tools";
import {drawValidation, loadingCanvas} from "./Drawing";
import {errorDrawing, WarningDownload} from "./utils/ErrorMessages";
import {resizingX, startResizeX} from "./utils/UtilsResize";
import PageView from "./PageView";
import {DownloadHandler} from "./utils/DownloadHandler";
import StepNavigationButtons from "./StepNavigationButtons";


const MetaInformation = ({pdf, pdfInfo, stepSelected, setStep, menuSize, setMenuSize, showPageMenu, setShowPageMenu, setStepSelected}) => {

    const metaData = useRef(false);
    const [renderI, setRenderI] = useState(0);
    const metaDataTypes = ["title", "author", "language",  "keywords", "subject"]

    // canvas image elements
    const canvasRef = useRef(null);
    const ctx = useRef(null);

    const [showLabels, setShowLabels] = useState(true);
    const [showArtifacts, setShowArtifacts] = useState(true);
    const viewed = useRef(Array.from({length: pdfInfo.numberOfPages}, _ => false));
    const pageNum = useRef(1);
    const [imageReady, setImageReady] = useState(true);
    const [showWarning, setShowWarning] = useState(false);
    const [startDownload, setStartDownload] = useState(false);

    const [zoomFactor, setZoomFactor] = useState(1);

    // resize start values
    const startResizeValueMenu = useRef(null);

    const queryClient = useQueryClient();

    // changing step
    useEffect(() => {
        if (stepSelected !== -1) {
            updateStep(() => {
                    setStep(stepSelected)
            });
        }
    }, [stepSelected]);

    // init canvas
    useEffect(() => {
        if (canvasRef.current) {
            ctx.current = canvasRef.current.getContext('2d');
        }
        metaDataQuery.refetch();
        structTreeQuery.refetch();
    }, []);

    // getting the meta data
    const metaDataQuery = useQuery({
        queryKey: ["metaData", pdfInfo.fileid],
        queryFn: () => getMetaData({pdfInfo: pdfInfo}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: data => {
            metaData.current = data;
            setRenderI(prevState => prevState + 1);
        }
    });

    // update meta information
    const metaMutation = useMutation({
        mutationFn: ({pdfInfo, documentInfo}) => updateMetaData({
            fileid: pdfInfo.fileid,
            documentInfo: documentInfo
        })
    })

    // get the struct tree
    const structTreeQuery = useQuery({
        queryKey: ["structTree", pdfInfo.fileid, pageNum.current],
        queryFn: () => getPageStructTree({pdfInfo: pdfInfo, pageNum: pageNum.current, addArtifacts:true, pdf: pdf}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: data => {
            drawing();
        }
    });

    // getting the image
    const pageImage = useQuery({
        queryKey: ["image", pdfInfo.fileid, pageNum.current],
        queryFn: () => getPageImagePdfJs({pdf: pdf, pageNum: pageNum.current, setImageReady: setImageReady}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: () => drawing()
    });

    /**
     * Helper function for updating the step
     */
    function updateStep(onSuccessFunction) {
        updateDocInfo({languageSelected: null});
        return metaMutation.mutate({pdfInfo: pdfInfo, documentInfo: metaData.current}, {
            onSuccess: () => {
                queryClient.invalidateQueries(["metaData", pdfInfo.fileid]).then(() => {
                    if (onSuccessFunction != null) {
                        onSuccessFunction();
                    }
                });
            }
        });
    }

    drawing();

    /**
     * helper function for drawing
     */
    function drawing() {
        if (!structTreeQuery.isLoading && imageReady && ctx.current != null && pageImage.data != null) {
            try {
                canvasRef.current.height = pageImage.data.naturalHeight;
                canvasRef.current.width = pageImage.data.naturalWidth;
                drawValidation({
                    ctx: ctx.current,
                    image: pageImage.data,
                    structTree: structTreeQuery.data,
                    showRegionLabels: showLabels,
                    showArtifacts: showArtifacts
                });
            }
            catch (e) {
                errorDrawing({errorMessage: e});
            }
        }
        else {
            loadingCanvas({ctx: ctx.current, canvasRef: canvasRef.current});
        }
    }

    function documentInfoForm() {
        return <Form className="flex-column overflow-y-auto"> {metaDataTypes.map(type => {
            return <Form.Group controlId={"form"+type} key={type}>
                    <Form.Label key={type + "-label"}>{type.charAt(0).toUpperCase() + type.slice(1) + (['language', 'title', 'author'].includes(type) ? " (required)" : "") }:</Form.Label>
                {type !== "language" ? <Form.Control key={type + "-control"} type={type} as="textarea" rows={1} defaultValue={metaData.current[type]}/> : getLanguageDropDown({selected: metaData.current[type]})}
                </Form.Group>
            })} </Form>;
    }

    function updateDocInfo({languageSelected}) {
        metaData.current = {
            title: document.getElementById('formtitle').value,
            author: document.getElementById('formauthor').value,
            language: languageSelected != null ? languageSelected : metaData.current.language,
            keywords: document.getElementById('formkeywords').value,
            subject: document.getElementById('formsubject').value
        };
        setRenderI(prevState => prevState + 1)
    }

    function getLanguageDropDown({selected}) {
        return <DropdownButton title={metaInformationConfig.languages[selected] != null ? metaInformationConfig.languages[selected]['en'] : "select a language"} id="dropdown-item-button" variant="light" key="langugageDropdown">
            {Object.keys(metaInformationConfig.languages).map(l => <DropdownItem key={l} onClick={() => updateDocInfo({languageSelected: l})}>{metaInformationConfig.languages[l]['en']}</DropdownItem>)}
        </DropdownButton>
    }

    function changePage(i) {
        pageNum.current = i + 1;
        setRenderI(prevState => prevState + 1);
    }


    return (
        <div className="workspaceArea"
             onMouseUp={() => startResizeValueMenu.current = null}
             onMouseMove={(e) => resizingX(e, startResizeValueMenu.current, setMenuSize)}
             style={startResizeValueMenu.current != null ? {userSelect: 'none'}: null}>
            <WarningDownload
                showWarning={showWarning}
                setShowWarning={setShowWarning}
                titleMessage={"Have you checked the meta information?"}
                bodyMessage={<>It seems that you have not add the following meta information: <ul> {(metaData.current.title === "" ? <li>Title</li> : null)} {(metaData.current.author === "" ? <li>Author</li> : null)} {(metaData.current.language === "" ? <li>Language</li> : null)}</ul> Do you want to check them before you continue?</>}
                handleDownload={() => setStartDownload(true)}/>
            <DownloadHandler
                pdfInfo={pdfInfo}
                setStep={setStep}
                startDownload={startDownload}
                setStartDownload={setStartDownload}
                pdf={pdf}
            />
            <div id="menu" className="menu" style={{width: menuSize + "px"}}>
                <div className="resizeHandleX" onMouseDown={(e) => startResizeValueMenu.current = startResizeX(e)}></div>
                <Instructions
                    title="Step 8: Check Meta Information and Final Results"
                    step={8}
                />
                {navigation({
                    callback: changePage,
                    length: pdfInfo.numberOfPages,
                    i: pageNum.current - 1,
                    title: "Pages",
                    viewed: viewed.current
                })}
                <div id="metaInformationView" className="blueBox">
                    {metaData.current ? documentInfoForm() : null}
                    <Button variant="light" onClick={() => setStartDownload(true)}><AiOutlineDownload/><br/>Download file</Button>
                </div>
                <StepNavigationButtons previousStep={() => setStepSelected(7)}/>
            </div>
            <PageView
                pageNum={pageNum.current}
                canvasRef={canvasRef}
                showLabels={showLabels}
                setShowLabels={setShowLabels}
                showArtifacts={showArtifacts}
                setShowArtifacts={setShowArtifacts}
                zoomFactor={zoomFactor}
                setZoomFactor={setZoomFactor}
                isLoading={structTreeQuery.isLoading || pageImage.isLoading}
                showPageMenu={showPageMenu}
                setShowPageMenu={setShowPageMenu}
                pdf={pdf}
                pdfInfo={pdfInfo}
                updateStep={updateStep}
            />
        </div>
    );
};

export default MetaInformation;
